<template>
  <div class="md:grid grid-cols-10">
    <div class="col-span-9 flex flex-col md:gap-y-[100px]">
      <div
        v-for="(row, idx) in rows"
        :key="idx"
        class="flex flex-col md:flex-row justify-between -md:border-b -md:last:border-b-0 border-gray-500"
      >
        <div v-for="(el, i) in row" :key="i" class="-md:border-b -md:last:border-b-0 border-gray-500 -md:pb-3 -md:pt-4">
          <AtomTitle tag="big" :content="el.title" />
          <AtomText :description="el.description" class="md:pr-[2vw] md:mt-[-10px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'
import AtomText from '../../atoms/builder/AtomText'

export default {
  name: 'OrganismInfographic',
  builder: {
    component: 'infographics',
  },
  components: {
    AtomTitle,
    AtomText,
  },
  props: ['item'],
  computed: {
    rows() {
      return (this.item || []).reduce((acc, item, index) => {
        if (index % 2 === 0) acc.push([])
        acc[acc.length - 1].push(item)
        return acc
      }, [])
    },
  },
}
</script>
