<template>
  <AtomContainer v-if="item.length">
    <div
      v-for="it in item"
      :key="it.id"
      class="md:grid gap-x-5 border-t last:border-b border-gray-500 md:border-gray-100 py-2 md:py-4"
      :class="`grid-cols-${cols}`"
    >
      <AtomText :content="it.col1" class="-md:pb-2 -md:w-full" />
      <div v-if="it.type === 'phone'" class="flex">
        <AtomText :content="it.col2" class="-md:w-1/2 -md:pb-2 -md:pr-1" />
        <div class="md:hidden">
          <AtomText :content="it.col3" />
          <AtomText :content="it.col4" />
        </div>
      </div>
      <div v-else-if="it.type === 'address'" class="-md:pb-2 -md:justify-between flex">
        <AtomButton
          v-if="it.link"
          tag="a"
          :href="it.link"
          class="-md:w-1/2 -md:self-end btn-outline btn-lg btn-icon mr-3 btn-map"
        >
          <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 22.978L10.1577 22.4037C15.6887 17.5742 18.5 13.3572 18.5 9.65045C18.5 4.40112 14.4029 0.650452 9.5 0.650452C4.59705 0.650452 0.5 4.40112 0.5 9.65045C0.5 13.3572 3.31131 17.5742 8.84227 22.4037L9.5 22.978ZM9.5 20.3139C4.80661 16.084 2.5 12.4997 2.5 9.65045C2.5 5.54705 5.66411 2.65045 9.5 2.65045C13.3359 2.65045 16.5 5.54705 16.5 9.65045C16.5 12.4997 14.1934 16.084 9.5 20.3139ZM9.5 4.65045C12.2614 4.65045 14.5 6.88903 14.5 9.65045C14.5 12.4119 12.2614 14.6505 9.5 14.6505C6.73858 14.6505 4.5 12.4119 4.5 9.65045C4.5 6.88903 6.73858 4.65045 9.5 4.65045ZM6.5 9.65045C6.5 7.9936 7.84315 6.65045 9.5 6.65045C11.1569 6.65045 12.5 7.9936 12.5 9.65045C12.5 11.3073 11.1569 12.6505 9.5 12.6505C7.84315 12.6505 6.5 11.3073 6.5 9.65045Z"
              fill="#282929"
            />
          </svg>
        </AtomButton>
        <div class="-md:w-1/2">
          <AtomText :content="it.col2" />
          <AtomText class="md:hidden" :content="it.col3" />
        </div>
      </div>
      <AtomText class="-md:hidden" :content="it.col3" />
      <AtomText class="-md:hidden" :content="it.col4" />
    </div>
  </AtomContainer>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomText from '../../atoms/AtomText'
import AtomContainer from '../../atoms/layouts/AtomContainer'

export default {
  name: 'OrganismSpaContacts',
  components: { AtomText, AtomContainer, AtomButton },
  builder: {
    component: 'spa-contacts',
  },
  props: ['item'],
  data() {
    let cols = 3
    for (let i = 0; i < this.item.length; i++) {
      if (this.item[i].col4) {
        cols = 4
      }
    }
    return {
      cols,
    }
  },
}
</script>
