<template>
  <Accordion class="border-t border-gray-500">
    <AccordionItem v-for="i in item" :key="i.id" :item="i" class="!py-0">
      <template slot="accordion-trigger">
        <AtomTitle class="py-3 pr-2" class-name="-md:!text-base" tag="h3">{{ i.title }}</AtomTitle>
      </template>
      <template slot="accordion-content">
        <div class="md:grid grid-cols-10 -md:border-b border-gray-500 pt-4 pb-10 md:py-4 md:mr-5">
          <AtomText class="col-span-4 col-start-6" :content="i.description" />
        </div>
      </template>
    </AccordionItem>
  </Accordion>
</template>

<script>
import Accordion from '../../atoms/accordion/AtomAccordion'
import AccordionItem from '../../atoms/accordion/AtomAccordionItem'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'OrganismAccordion',
  builder: {
    component: 'accordion',
    layout: 'gap-content-gap',
  },
  components: {
    Accordion,
    AccordionItem,
    AtomTitle,
    AtomText,
  },
  props: ['item'],
}
</script>
