<template>
  <div class="relative">
    <div
      ref="label"
      class="cursor-pointer dropdown"
      :class="{
        invisible: isOpened,
        ['w-full']: !icon,
        ['dark-theme']: !themeLight,
      }"
      @click="onOpen()"
    >
      <svg v-if="icon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.5 7.49998C9.22589 7.49998 10.625 6.10087 10.625 4.37498C10.625 2.64909 9.22589 1.24998 7.5 1.24998C5.77411 1.24998 4.375 2.64909 4.375 4.37498C4.375 6.10087 5.77411 7.49998 7.5 7.49998Z"
          :stroke="icon"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.8687 13.75C12.8687 11.3312 10.4625 9.375 7.49997 9.375C4.53747 9.375 2.13123 11.3312 2.13123 13.75"
          :stroke="icon"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div
        v-else
        class="justify-between rounded-[30px] px-4 py-2 md:px-[37px] md:py-[11px] bg-gray-100 border border-gray-100 w-full print:bg-transparent print:border-0 print:!px-0"
        :class="{
          ['inline-flex']: autoWidth,
          ['!bg-transparent']: themeLightSelected,
          flex: !autoWidth,
          ['!px-5 !py-1 !rounded-[50px] !border-gray-500 items-center']: small,
        }"
      >
        <div class="flex max-w-full">
          <span
            class="-md:py-1 dropdown-label text-white text-base leading-4 md:leading-[36px] md:text-[30px] print:text-gray-100 print:!text-[14px] truncate"
            :class="{ ['!text-gray-100']: themeLightSelected, ['uppercase']: !themeLight, ['!text-base']: small }"
            >{{ customLabel || (current && current.text) }}</span
          >
          <sup
            v-if="current && current.sup && !hideSup"
            class="dropdown-sup text-white text-sm mt-1 md:text-[16px] md:mt-[5px] ml-[4px]"
            >{{ current.sup }}</sup
          >
        </div>
        <div v-if="clearable && index > -1" class="p-1 ml-[6px] relative right-[-8px]" @click.stop="clear">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            viewBox="0 0 14 13"
            fill="none"
            class="text-white w-2 md:w-3 self-center"
          >
            <path d="M1 0.5L13 12.5M1 12.5L13 0.5" :stroke="themeLightSelected ? '#282929' : 'white'" />
          </svg>
        </div>
        <svg
          v-else-if="items.length > 1"
          class="dropdown-arrow text-white ml-[23px] w-3 md:w-6 self-center print:!hidden"
          :class="{ ['!text-gray-100']: themeLightSelected, ['!w-3']: small }"
          width="25"
          height="16"
          viewBox="0 0 25 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            :class="{ ['fill-gray-100']: themeLightSelected }"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.47431 0.553589L12.4129 12.4866L23.3514 0.55359L24.8258 1.90504L12.4129 15.4464L0 1.90504L1.47431 0.553589Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <client-only>
      <Portal v-if="isOpened" to="button_dropdown">
        <transition :duration="{ enter: 300, leave: 200 }" name="dropdown" appear>
          <div
            ref="dropdown"
            class="fixed bg-gray-100 pr-0 pl-2 md:pl-5 py-2 md:py-5 -md:w-[calc(100%_-_24px)] md:min-w-[402px] rounded-[10px] md:rounded-[30px] -md:!left-3"
            :class="{
              ['!bg-white']: themeLight,
              ['!min-w-0']: small && !dropdownMinWidth,
              ['!p-[9px] !rounded-lg']: small,
              ['-md:mt-[12px] dropdown-wrap']: !small,
              invisible: !isPositioned,
            }"
          >
            <Scroll axis="y" :theme="themeLight ? 'white' : 'dark'">
              <div style="overflow: visible" :class="{ ['max-h-[200px]']: small, ['max-h-[330px]']: !small }">
                <template v-for="(item, i) in items">
                  <div
                    v-if="!item.placeholder"
                    :key="`${item.text}_${i}`"
                    class="p-2 md:py-[10px] px-3 rounded-[10px] hover:cursor-pointer hover:bg-[#323234] flex -md:mb-1 mb-2 last:!mb-0"
                    :class="{
                      ['hover:!bg-gray-600']: themeLight,
                      ['!py-2 !px-3']: small,
                      'bg-gray-300': i === index,
                      '!bg-gray-600': i === index && themeLight,
                    }"
                    @click="onSelect(i)"
                  >
                    <span
                      class="text-white text-base first-letter:uppercase md:leading-[36px] md:text-[30px]"
                      :class="{ ['!text-gray-100']: themeLight, ['uppercase']: !themeLight, ['!text-base']: small }"
                      >{{ item.text }}</span
                    >
                    <sup
                      v-if="current && current.sup && !hideSup"
                      class="text-white text-sm md:text-[16px] mt-[6px] md:mt-[5px] ml-[4px]"
                      :class="{ ['!text-gray-100']: themeLight, ['!text-sm']: small }"
                      >{{ item.sup }}</sup
                    >
                  </div>
                </template>
              </div>
            </Scroll>
          </div>
        </transition>
      </Portal>
      <Backdrop v-if="isOpened" @close="isOpened = false" />
    </client-only>
  </div>
</template>

<script>
import Scroll from '../templates/layouts/Scroll'
import Backdrop from './layouts/AtomBackdrop'

export default {
  name: 'AtomButtonDropdown',
  components: {
    Backdrop,
    Scroll,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
    autoWidth: {
      type: Boolean,
      default: true,
    },
    themeLight: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideSup: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: String,
      default: null,
    },
    invertThemeSelected: {
      type: Boolean,
      default: false,
    },
    dropdownAutoWidth: {
      type: Boolean,
      default: false,
    },
    dropdownMinWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: null,
      isOpened: false,
      isPositioned: false,
    }
  },
  computed: {
    index() {
      return typeof this.currentIndex === 'number' ? this.currentIndex : this.initialIndex
    },
    current() {
      if (this.items && this.items[this.index]) {
        return this.items[this.index]
      }
      return null
    },
    themeLightSelected() {
      if (this.invertThemeSelected && this.index > -1) return !this.themeLight
      return this.themeLight
    },
  },
  watch: {
    isOpened() {
      if (this.isOpened) {
        const y = this.$root.$loco.scroll.instance.scroll.y
        this.$root.$loco.setScroll(0, y)
        this.smartPosition()
      } else {
        this.isPositioned = false
      }
    },
    items() {
      this.onSelect(this.initialIndex, true)
    },
    initialIndex() {
      if (this.index !== this.initialIndex && this.items[this.initialIndex]) {
        this.currentIndex = this.initialIndex
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    onSelect(i, force) {
      const shouldEmit = this.index !== i
      this.currentIndex = i
      this.isOpened = false
      if (shouldEmit || force) this.$emit('select', this.currentIndex)
    },
    clear() {
      this.onSelect(-1)
    },
    onOpen() {
      if (!this.items?.length) return
      if (this.items.length === 1) return
      this.isOpened = true
    },
    smartPosition(dropdown) {
      if (!this.$refs.dropdown) {
        this.timeout = setTimeout(this.smartPosition, 10)
        return
      }
      const CONTAINER_PADDING_TOP = this.small ? 12 : 16
      const rectLabel = this.$refs.label.getBoundingClientRect()
      let rectDropdown = this.$refs.dropdown.getBoundingClientRect()
      const width = this.dropdownAutoWidth ? 'auto' : `${getWidth()}px`
      this.$refs.dropdown.style.cssText = `width: ${width};`
      // recalc box top after width set
      rectDropdown = this.$refs.dropdown.getBoundingClientRect()
      this.$refs.dropdown.style.cssText += `top: ${getTop()}px;`
      if (this.dropdownMinWidth) {
        this.$refs.dropdown.style.cssText += `min-width: ${Math.round(rectLabel?.width) || 0}px;`
      }
      // recalc box left after width set
      rectDropdown = this.$refs.dropdown.getBoundingClientRect()
      this.$refs.dropdown.style.cssText += `left: ${getLeft()}px;`
      function getTop() {
        const openDown =
          window.innerHeight - rectLabel.y - rectLabel.height > rectDropdown.height - CONTAINER_PADDING_TOP
        if (openDown) return rectLabel.y - CONTAINER_PADDING_TOP
        return rectLabel.y + rectLabel.height - rectDropdown.height + CONTAINER_PADDING_TOP
      }
      this.isPositioned = true
      function getWidth() {
        if (rectLabel.width > rectDropdown.width) return rectLabel.width
        return rectDropdown.width
      }
      function getLeft() {
        if (rectLabel.x + rectDropdown.width >= window.innerWidth) return window.innerWidth - rectDropdown.width
        return rectLabel.x
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.dropdown-wrap {
  ::v-deep .scroll-bar {
    @apply md:right-3;
  }
}
div.dropdown-enter,
div.dropdown-leave-to {
  @apply opacity-0;
}
div.dropdown-enter-to,
div.dropdown-leave {
  @apply opacity-100;
}
div.dropdown-enter-active {
  transition: opacity 0.3s var(--cubic-bezier);
}
div.dropdown-leave-active {
  transition: opacity 0.2s var(--cubic-bezier);
}
</style>
