<script>
export default {
  name: 'MarkdownLinkNavigation',
  mounted() {
    this.$el && this.$el.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    this.$el && this.$el.removeEventListener('click', this.handleClick)
  },
  methods: {
    handleClick(event) {
      const a = event.target
      if (a.nodeName !== 'A') return
      const href = a.getAttribute('href')
      event.preventDefault()
      if (href.startsWith('http')) {
        window.open(href, '_blank')
      } else {
        this.$router.push(`/${href}`.replace(/^\/+/, '/'))
      }
    },
  },
  render() {
    return this.$scopedSlots.default({})
  },
}
</script>
