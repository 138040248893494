<template>
  <div class="flex justify-between items-center py-2 md:py-5 border-t border-b border-gray-100">
    <AtomTitle tag="h2" class="w-1/2" :sup="control.titleSup">{{ control.title }}</AtomTitle>
    <AtomButton :to="control.to">{{ control.button }}</AtomButton>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'MoleculeControlList',
  components: {
    AtomTitle,
    AtomButton,
  },
  props: ['control'],
}
</script>
