<template>
  <section class="pt-5 print:!hidden">
    <div class="container">
      <div class="relative bg-gray-600 rounded-[10px] pt-12 md:pt-24 md:pb-8 overflow-hidden">
        <div class="md:grid grid-cols-12">
          <div class="md:col-start-2 lg:col-start-3 col-span-7 lg:col-span-5">
            <AtomTitle tag="h2" class="px-20 md:px-0 -md:text-center">{{ apps.title }}</AtomTitle>
            <MoleculeAppsTags class="hidden md:flex md:pt-7 pb-24 max-w-[470px]" :tags="apps.tags" />
            <MoleculeBtnApps class="mt-3 md:mt-0" />
          </div>
        </div>
        <div class="mx-auto max-w-[400px] md:max-w-none">
          <AtomImage
            class="-md:hidden max-w-[800px] img md:w-1/2 aspect-[721/603] !bg-[length:170%] md:!bg-contain md:absolute md:bottom-0 md:right-0 !bg-[-45%_top] md:!bg-bottom"
            :src="apps.image"
          />
          <AtomImage class="md:hidden img aspect-[296/232] mt-4" :src="apps.imageMobile" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomTitle from '../../atoms/AtomTitle'
import MoleculeAppsTags from '../../molecules/layouts/MoleculeAppsTags'
import MoleculeBtnApps from '../../molecules/layouts/MoleculeBtnApps'

export default {
  name: 'OrganismApps',
  components: {
    AtomTitle,
    AtomImage,
    MoleculeBtnApps,
    MoleculeAppsTags,
  },
  builder: {
    component: 'timetable-app',
  },
  data: () => {
    return {
      apps: {
        title: 'С приложением еще удобнее',
        image: 'layouts/mobile.webp',
        imageMobile: 'layouts/m_mobile.webp',
        tags: [
          {
            name: 'пополнять счет',
          },
          {
            name: 'записываться на тренировки',
          },
          {
            name: 'следить за расписанием',
          },
          {
            name: 'управлять опциями карты',
          },
          {
            name: 'замораживать карту',
          },
          {
            name: 'продлевать членство',
          },
          {
            name: 'знакомиться с тренерами',
          },
          {
            name: 'оплачивать услуги',
          },
        ],
      },
    }
  },
}
</script>

<style lang="postcss" scoped>
.img {
  @media (max-width: 959px) {
    background-position: 70% 30% !important;
  }
}
</style>
