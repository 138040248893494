<template>
  <div class="md:grid grid-cols-12 gap-x-5">
    <div class="col-start-3 col-span-6">
      <AtomTitle class="-md:mb-3" tag="h2" :content="titleLeft" />
    </div>
    <div v-if="titleRight || subtitleRight" class="col-start-10 col-span-3">
      <div
        class="-md:flex items-center -md:flex-row -md:mb-3 -md:py-2 -md:border-t -md:border-b md:border-l border-gray-100 md:pl-4"
      >
        <AtomTitle class="-md:mb-1 -md:mr-2" tag="h2" :content="titleRight" />
        <AtomText v-if="subtitleRight" class="md:mt-1" :content="subtitleRight" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'AtomTitleDividesTitle',
  builder: {
    component: 'title-divides-title',
  },
  components: {
    AtomTitle,
    AtomText,
  },
  props: ['titleLeft', 'titleRight', 'subtitleRight'],
}
</script>
