<template>
  <div class="h-[2px] bg-gray-500 w-full relative">
    <div class="absolute h-[2px] bg-gray-100 left-0" :style="{ width: progress * 10 + '%' }" />
  </div>
</template>

<script>
export default {
  name: 'AtomProgress',
  props: ['progress'],
}
</script>
