<template>
  <div class="pl-3 md:pl-5 border-l border-gray-100 text-base md:text-lg" :class="className">
    <MarkdownLinkNavigation>
      <div v-if="description" class="wrap-text" v-html="description" />
    </MarkdownLinkNavigation>
  </div>
</template>

<script>
import MarkdownLinkNavigation from '../../builder/core/MarkdownLinkNavigation'

export default {
  name: 'AtomTextHighlighted',
  components: {
    MarkdownLinkNavigation,
  },
  props: ['description', 'className'],
  builder: {
    component: 'vertical-line-text',
  },
}
</script>
