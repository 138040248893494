<template>
  <div class="md:container">
    <client-only>
      <OrganismTimeTable
        ref="timetable"
        :isday="$device.isMobile || $device.isTablet ? true : false"
        :days="$device.isMobile || $device.isTablet ? [day] : days"
        :times="times"
        :timetables="timetables"
        :date="date"
        :footer="clubItems"
      />
    </client-only>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import OrganismTimeTable from '../timetable/OrganismTimeTable'

export default {
  name: 'TemplateTimeTable',
  builder: {
    component: 'timetable',
  },
  components: {
    OrganismTimeTable,
  },
  props: ['context'],
  data: () => {
    return {
      error: false,
    }
  },
  async fetch() {
    await this.getTimetable()
  },
  computed: {
    ...mapState({
      date: state => state.modules.classes.date,
    }),
    ...mapGetters({
      times: 'modules/classes/times',
      days: 'modules/classes/days',
      day: 'modules/classes/day',
      timetables: 'modules/classes/timetablesFiltered',
    }),
    clubs() {
      return this.$store.getters['modules/content/clubs']
    },
    clubItems() {
      return [
        {
          title: 'Расписание в клубах',
          controls: this.clubs
            .filter(club => club.city.id == this.context.cityId)
            .map(club => ({ title: club.title, to: `/timetable/${club.slug}/adult` })),
        },
      ]
    },
  },
  watch: {
    async 'context.club'() {
      await this.getTimetable()
    },
    async 'context.date'() {
      await this.getTimetable({ requestIfOutOfWeek: true })
    },
    async 'context.age'() {
      await this.getTimetable()
    },
  },
  methods: {
    ...mapActions({
      getTimetableAction: 'modules/classes/getTimetable',
    }),
    async getTimetable(conditions = {}) {
      if (this.$refs.timetable) {
        this.$refs.timetable.closeModal()
      }
      this.error = !(await this.getTimetableAction({ params: this.context, conditions }))
    },
  },
}
</script>
