<template>
  <AtomTitle tag="h2" :content="description" :animation="animation" />
</template>

<script>
import AtomTitle from '../AtomTitle'

export default {
  name: 'AtomTitleSimple',
  builder: {
    component: 'title-simple',
  },
  components: {
    AtomTitle,
  },
  props: ['description', 'animation'],
}
</script>
