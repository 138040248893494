<template>
  <component :is="tag" class="tag"
    ><span>{{ content }}</span></component
  >
</template>

<script>
export default {
  name: 'AtomTag',
  props: ['tag', 'content'],
}
</script>

<style lang="postcss" scoped>
.tag {
  @apply relative overflow-hidden inline-flex items-center text-center rounded-[51px] text-sm md:text-base py-1 px-5 text-gray-100 border-gray-100 md:shadow-linear;
  transition: all 0.65s var(--ease-in-out-soft);
  &:not(.hide-hover-mobile) {
    &:hover {
      @apply bg-gray-100 text-white;
    }
  }
  &.disabled {
    @apply !text-white !bg-gray-500 !border-gray-500;
  }
  span {
    @apply relative z-[1];
  }
}
</style>
