<template>
  <div class="grid md:grid-cols-2 md:gap-x-5 px-3 md:px-5 mb-24 md:mb-48">
    <MoleculeItem v-for="item in list" :key="item.title" :item="item" />
    <ModalVisitClub
      v-if="clubToVisit"
      :strapi-image-object="clubToVisit.headerImage || clubToVisit.image"
      @close="clubToVisit = null"
    />
  </div>
</template>

<script>
import MoleculeItem from '../../molecules/MoleculeItem'
import ModalVisitClub from '../modals/VisitClub'

export default {
  name: 'OrganismClubsList',
  components: {
    MoleculeItem,
    ModalVisitClub,
  },
  builder: {
    component: 'clubs-list',
  },
  props: ['context'],
  data() {
    return {
      clubToVisit: null,
    }
  },
  computed: {
    list() {
      const clubs = this.$store.getters['modules/content/clubs']
      const cityId = this.context.cityId
      const hasAtLeastOne = Boolean(clubs.find(c => c.city.id == cityId))
      return clubs
        .filter(c => {
          return hasAtLeastOne ? c.city.id == cityId : true
        })
        .map(club => {
          return {
            title: club.title,
            strapiImageObject: club.image,
            address: club.address,
            openSoon: !club.open,
            link: `/clubs/${club.slug}`,
            controls: [
              club.open && {
                title: 'Расписание',
                to: `/timetable/${club.slug}/adult`,
              },
              club.spa && {
                title: 'SPA',
                to: `/spa/${club.slug}`,
              },
              club.open && {
                title: 'Посетить',
                action: () => {
                  this.clubToVisit = club
                },
              },
              club.open &&
                club.phone && {
                  title: '',
                  tel: club.phone,
                },
              !club.open && {
                title: 'Скоро открытие',
                disabled: true,
              },
            ].filter(Boolean),
          }
        })
    },
  },
}
</script>

<style lang="postcss" scoped></style>
