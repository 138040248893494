<template>
  <div>
    <div class="flex justify-between mb-2">
      <div />
      <div class="-md:w-full">
        <AtomButtonDropdown
          class="ml-2"
          theme-light
          :invert-theme-selected="true"
          small
          :custom-label="initialCategoryName"
          :items="items"
          :initial-index="initialCategory"
          :clearable="true"
          :dropdown-auto-width="true"
          :dropdown-min-width="true"
          @select="onFilterSelect($event)"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-5">
      <nuxt-link
        v-for="trainer in trainersFiltered"
        :key="trainer.id"
        :to="`/team/${$route.params.club}/trainer/${trainer.id}`"
      >
        <MoleculeCard :card="trainer" />
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import MoleculeCard from '../../molecules/team/MoleculeCard'

import AtomButtonDropdown from '~/components/atoms/AtomButtonDropdown'

export default {
  name: 'TemplateTeam',
  components: {
    AtomButtonDropdown,
    MoleculeCard,
  },
  builder: {
    component: 'trainers-list',
  },
  props: ['context'],
  data: () => {
    return {
      trainers: [],
      categories: [],
    }
  },
  async fetch() {
    Promise.all([await this.getTrainers()])
  },
  computed: {
    items() {
      return this.categories
    },
    initialCategoryName() {
      const category = this.categories.find(({ slug }) => slug == this.$route.query.category)
      return category ? category.text : 'Все направления'
    },
    initialCategory() {
      return this.categories.findIndex(({ slug }) => slug == this.$route.query.category)
    },
    trainersFiltered() {
      const slug = this.$route.query.category
      return slug ? this.trainers.filter(item => item.categories && item.categories[slug]) : this.trainers
    },
  },
  watch: {
    async 'context.club'() {
      await this.getTrainers()
    },
  },
  methods: {
    onFilterSelect(i) {
      this.$router
        .replace({
          query: { category: this.categories[i] ? this.categories[i].slug : undefined },
        })
        .catch(() => {})
    },
    async getTrainers() {
      const id = `fitbase/${this.context.club}/trainers`
      const trainers =
        this.$store.state.modules.content.id || (await this.$store.dispatch('modules/content/getContent', { id })) || []
      const allCategories = []
      this.trainers = trainers
        .filter(trainer => trainer.photo)
        .map(trainer => {
          const categories = { items: [] }
          trainer?.custom_fields.forEach(field => {
            const selected = field?.key?.value === '1'
            categories[field.custom_field_id] = selected
            if (selected) {
              categories.items.push({ name: field.name })
              if (!allCategories.find(category => field.custom_field_id === category.slug)) {
                allCategories.push({
                  text: field.name,
                  slug: field.custom_field_id,
                })
              }
            }
          })

          return {
            ...trainer,
            categories,
          }
        })
      this.categories = allCategories
    },
  },
}
</script>
