<template>
  <div class="flex flex-col md:grid grid-cols-12 gap-x-9">
    <div class="col-start-3 col-span-6 -md:order-2">
      <AtomText v-if="descriptionLeft" class="md:mt-1" :content="descriptionLeft" />
    </div>
    <div class="col-start-10 col-span-3 -md:order-1 ml-0 md:-ml-0.5">
      <div class="-md:flex items-center -md:flex-row -md:mb-3 -md:py-2 -md:border-t -md:border-b border-gray-100">
        <AtomText v-if="descriptionRight" class="md:mt-1" :content="descriptionRight" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomText from '../../atoms/AtomText'

export default {
  name: 'AtomDescDividesDesc',
  builder: {
    component: 'desc-divides-desc',
  },
  components: {
    AtomText,
  },
  props: ['descriptionLeft', 'descriptionRight'],
}
</script>
