<template>
  <div class="grid grid-cols-12 md:gap-x-5">
    <div class="col-span-12 md:col-start-3 md:col-span-10">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AtomContent',
}
</script>
