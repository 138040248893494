<template>
  <div class="flex flex-col md:grid grid-cols-12 gap-x-9">
    <div class="col-start-3 col-span-6 -md:order-2">
      <AtomText v-if="text" class="md:mt-1" :content="text" />
    </div>
    <div class="col-start-10 col-span-3 -md:order-1 ml-0 md:-ml-0.5">
      <div class="-md:flex items-center -md:flex-row -md:mb-1 -md:py-2 gap-2">
        <AtomButton v-if="shouldRender(button1)" v-bind="getButonAttrs(button1)" class="md:mt-1 btn-sm">{{
          button1.text
        }}</AtomButton>
        <AtomButton v-if="shouldRender(button2)" v-bind="getButonAttrs(button2)" class="md:mt-1 btn-sm">{{
          button2.text
        }}</AtomButton>
      </div>
    </div>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomText from '../../atoms/AtomText'

export default {
  name: 'ClubSpaDescription',
  builder: {
    component: 'club-spa-description',
  },
  components: {
    AtomText,
    AtomButton,
  },
  props: ['text', 'button1', 'button2'],
  methods: {
    getButonAttrs(button) {
      const isYclients = button.link && /yclients/i.test(button.link)
      if (isYclients) {
        return {
          'data-yclients-url': `${button.link}/widgetJS`,
        }
      }
      return {
        href: button.link,
        tag: 'a',
      }
    },
    shouldRender(button) {
      return Boolean(button && button.link && button.text)
    },
  },
}
</script>
