<template>
  <div class="md:grid grid-cols-2 mb-10 md:mb-16">
    <div class="text-sm md:text-base -md:mb-1">{{ list.label }}</div>
    <AtomList :list="list.list" />
  </div>
</template>

<script>
import AtomList from '../../atoms/AtomList'

export default {
  name: 'MoleculeList',
  components: {
    AtomList,
  },
  props: ['list'],
}
</script>
