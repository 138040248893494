<template>
  <video
    :class="{
      'min-w-screen w-full': fullWidth,
      'md:hidden': only === 'desktop' && !isDesktop(),
      'object-cover': fit,
    }"
    v-bind="attrs"
    class="min-h-screen max-w-none max-h-none h-auto"
    autoplay
    muted
    loop
  >
    <source :src="src" type="video/mp4" />
  </video>
</template>

<script>
export default {
  name: 'AtomVideo',
  props: {
    src: {
      type: String,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    only: {
      type: String, // desktop | void
      default: null,
    },
    fit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attrs() {
      if (this.only === 'desktop' && !this.isDesktop()) {
        return {
          preload: 'none',
        }
      }
      return {
        playsinline: true,
      }
    },
  },
  methods: {
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
  },
}
</script>
