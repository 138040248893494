<template>
  <p :id="hash" :data-sticky-ref="hash" />
</template>

<script>
export default {
  name: 'StickyMenuRef',
  builder: {
    component: 'sticky-menu',
  },
  props: ['hash'],
}
</script>
