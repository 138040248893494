<template>
  <FitnessBase :narrow="false" />
</template>

<script>
import FitnessBase from './FitnessBase'

export default {
  name: 'MainFitness',
  components: {
    FitnessBase,
  },
  builder: {
    component: 'main-fitness',
  },
}
</script>
