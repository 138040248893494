<template>
  <div class="flex flex-col">
    <div
      v-if="titleLeft || titleRight || subtitleRight"
      class="md:grid grid-cols-12 gap-x-5 md:mb-5"
      :class="{ ['md:order-2 md:mt-10 md:mb-0']: showBelow }"
    >
      <div class="col-start-3 col-span-7 pl-3 md:pr-5">
        <AtomTitle class="-md:mb-3 whitespace-pre-line" tag="h2" :content="titleLeft" />
      </div>
      <div v-if="titleRight || subtitleRight" class="col-start-10 col-span-3 -md:px-3">
        <div
          class="-md:flex items-center -md:flex-row -md:mb-3 -md:py-2 -md:border-t -md:border-b md:border-l border-gray-100 md:pl-4"
        >
          <AtomTitle
            class="-md:mb-0 -md:pt-1 -md:mr-2 whitespace-pre-line !mt-0"
            class-name="!pt-0"
            tag="h2"
            :content="titleRight"
          />
          <AtomText v-if="subtitleRight" class="md:mt-1 whitespace-pre-line" :content="subtitleRight" />
        </div>
      </div>
    </div>
    <div class="slider" :class="{ ['md:order-1']: showBelow }" @click="swipe">
      <client-only>
        <swiper ref="swiper" :options="options">
          <swiper-slide v-for="(slide, i) in item" :key="i">
            <AtomImage :strapi-image-object="slide.image" />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
  </div>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'Slider',
  components: {
    AtomImage,
    AtomTitle,
    AtomText,
  },
  builder: {
    component: 'slider',
  },
  props: ['item', 'titleLeft', 'titleRight', 'subtitleRight', 'showBelow'],
  data() {
    return {
      options: {
        slidesPerView: 'auto',
        loop: true,
        speed: 1000,
      },
    }
  },
  methods: {
    swipe(e) {
      const box = e.currentTarget.getBoundingClientRect()
      if (box.x + box.width / 2 > e.x) {
        this.$refs.swiper.swiperInstance.slidePrev()
      } else {
        this.$refs.swiper.swiperInstance.slideNext()
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.slider {
  .swiper-container {
    cursor: url("data:image/svg+xml,%3Csvg width='101' height='47' viewBox='0 0 101 47' fill='none' style='transform: rotate(180deg)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.7881 23.3809L76.4258 1.01862M98.7881 23.3809L76.1017 46.0672M98.7881 23.3809L0.000974143 23.3812' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A")
        50 23,
      pointer;
    &::after {
      z-index: 1;
      content: '';
      cursor: url("data:image/svg+xml,%3Csvg width='101' height='47' viewBox='0 0 101 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.7881 23.3809L76.4258 1.01862M98.7881 23.3809L76.1017 46.0672M98.7881 23.3809L0.000974143 23.3812' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A")
          50 23,
        pointer;
      @apply absolute inset-y-0 right-0 w-[50%];
    }
  }
  .swiper-slide {
    @apply md:min-w-[calc(75%_+_10px)] md:w-[calc(75%_+_10px)] min-w-[80vw] w-[80vw] md:mr-5 mr-3 aspect-[758/666] md:aspect-[1007/566];
    img {
      @apply pointer-events-none w-full object-cover h-full;
    }
    &.swiper-slide-active {
      @apply -md:ml-3;
    }
  }
}
</style>
