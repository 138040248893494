<template>
  <div class="flex flex-col justify-center items-center py-5 px-1 border-t border-r border-b border-gray-500">
    <div class="text-base text-inherit print:text-[12px] print:font-semibold">{{ day }}</div>
    <div class="text-sm text-inherit">{{ date }}</div>
  </div>
</template>

<script>
export default {
  name: 'AtomHeadDay',
  props: ['day', 'date'],
}
</script>
