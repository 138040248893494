<template>
  <NuxtLink :to="`/clubs/${club.slug}`" class="flex flex-col md:flex-row gap-5 py-5 border-t border-gray-100">
    <div class="club-text md:w-1/2 flex flex-col justify-between">
      <AtomTitle tag="h2" :content="title" animation="rotation" />
      <AtomText class="hidden md:block w-1/2" animation="fade">{{ club.address }}</AtomText>
    </div>
    <div class="md:w-1/2">
      <div class="aspect-[648/540] block relative overflow-hidden">
        <AtomImageParallax
          v-if="club.image"
          class="club-img w-full min-h-[238px] aspect-[648/540]"
          :strapi-image-object="club.image"
        />
        <AtomVideo
          v-show="!isMobile"
          v-if="club.previewVideo && club.previewVideo.url"
          class="club-video !w-full min-h-[238px] object-cover h-full opacity-0 absolute left-0 right-0 top-0 bottom-0"
          :src="club.previewVideo.url"
          only="desktop"
        />
      </div>
    </div>
  </NuxtLink>
</template>

<script>
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'
import AtomVideo from '../../atoms/AtomVideo'

export default {
  name: 'MoleculeItemRow',
  components: {
    AtomTitle,
    AtomText,
    AtomImageParallax,
    AtomVideo,
  },
  props: ['club'],
  data() {
    return {
      isMobile: true,
    }
  },
  computed: {
    title() {
      return this.$device.isDesktop
        ? (this.club.title || '').toUpperCase().split(/\s+/).join('<br />')
        : (this.club.title || '').toUpperCase()
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 960
  },
}
</script>

<style lang="postcss" scoped>
.club-video {
  transition: opacity 0.4s var(--cubic-bezier);
}
</style>
