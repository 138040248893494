<template>
  <div class="grid md:grid-cols-2 md:gap-x-5 px-3 md:px-5">
    <MoleculeItem v-for="item in list" :key="item.id" :item="item" />
  </div>
</template>

<script>
import MoleculeItem from '../../molecules/MoleculeItem'

export default {
  name: 'OrganismSpaClubsList',
  components: {
    MoleculeItem,
  },
  builder: {
    component: 'spa-clubs-list',
  },
  props: ['context'],
  data() {
    return {
      clubToVisit: null,
    }
  },
  computed: {
    list() {
      const clubs = this.$store.getters['modules/content/clubsWithSpa']
      const cityId = this.context.cityId
      const hasAtLeastOne = Boolean(clubs.find(c => c.city.id == cityId))
      return clubs
        .filter(c => {
          return hasAtLeastOne ? c.city.id == cityId : true
        })
        .map(club => {
          return {
            id: club.id,
            title: club.spaTitle,
            strapiImageObject: club.spaImage,
            address: club.address,
            openSoon: !club.open,
            link: `/spa/${club.slug}`,
            controls: [
              {
                dataset: {
                  'data-yclients-url': `${club.spaClient || 'https://n794050.yclients.com'}/widgetJS`,
                },
                title: 'Онлайн-запись',
              },
              club.spaWhatsapp && {
                title: 'WhatsApp',
                tag: 'a',
                href: club.spaWhatsapp,
              },
            ].filter(Boolean),
          }
        })
    },
  },
}
</script>

<style lang="postcss" scoped></style>
