<template>
  <div class="container md:grid grid-cols-12 md:gap-x-5">
    <div class="col-span-10 col-start-3">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AtomContainer',
}
</script>

<style lang="postcss"></style>
