<template>
  <div
    class="card-team w-full cursor-pointer flex flex-col before:md:content-[''] before:md:float-left before:md:pt-[138%] after:md:content-[''] after:md:block after:md:clear-left md:justify-between overflow-hidden relative md:py-5 md:px-4 rounded-[10px] md:bg-gray-600"
  >
    <AtomText
      tag="h3"
      class="!text-sm -md:px-2 -md:mt-2 -md:order-2 relative md:absolute z-10 top-layer"
      :content="card.function"
    />
    <AtomImage
      v-if="card.photo"
      class="absolute left-0 right-0 top-0 bottom-0 rounded-[10px] img-hover -md:hidden"
      :src="card.photo"
    />
    <div
      class="-md:relative -md:p-2 -md:order-1 before:-md:content-[''] before:-md:float-left before:-md:pt-[137.577%] after:-md:content-[''] after:-md:block after:-md:clear-left flex flex-col md:justify-end md:aspect-auto rounded-[10px] -md:bg-gray-600 overflow-hidden"
    >
      <AtomImage
        v-if="card.photo"
        class="absolute left-0 right-0 top-0 bottom-0 rounded-[10px] img-hover md:hidden"
        :src="card.photo"
      />
      <AtomTag
        tag="span"
        class="self-start -md:!absolute -md:!p-0 -md:!border-0 !text-gray-100 md:!border-gray-100 md:!bg-gray-600 hide-hover-mobile"
        :content="fio"
      />
    </div>
  </div>
</template>

<script>
// import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomImage from '../../atoms/AtomImage'
import AtomTag from '../../atoms/AtomTag'
import AtomText from '../../atoms/AtomText'

export default {
  name: 'MoleculeCard',
  components: {
    AtomText,
    AtomTag,
    // AtomImageParallax,
    AtomImage,
  },
  props: ['card'],
  computed: {
    fio() {
      return [this.card.name, this.card.surname].filter(Boolean).join(' ')
    },
  },
}
</script>

<style lang="postcss" scoped>
.card-team {
  &:hover {
    .img-hover {
      scale: 1.03;
    }
    ::v-deep .tag {
      @apply md:!bg-gray-100;
      transition-delay: 0.2s;
      span {
        @apply md:text-white;
      }
      &:before {
        transform: translateZ(0);
      }
    }
  }
  .top-layer {
    transform: translate3d(0px, 0px, 10px);
  }
  .img-hover {
    height: 100%;
    transition: scale 1s var(--ease);
    scale: 1;
  }
  .tag {
    @apply grid md:h-[32px] z-0 relative -md:rounded-none bg-transparent;
    transition: background 0.3s var(--ease-out);
    ::v-deep span {
      @apply md:truncate -md:text-left;
    }
    &:before {
      @apply absolute left-0 w-full z-[-1] bottom-[-0.1rem] md:content-[''] bg-gray-100;
      height: calc(100% + 0.2rem);
      transform: translate3d(0, 100%, 0);
      transition: transform 0.3s var(--ease-out);
    }
  }
}
</style>
