<template>
  <div
    class="md:grid grid-cols-12 linear-gradient-gray px-3 md:px-0 py-3 md:pt-48 md:pb-44 mx-3 my-5 md:mx-5 rounded-[10px] -md:mt-24"
  >
    <div class="col-start-3 col-span-10">
      <AtomTitle tag="h2">Онлайн-запись <br class="md:hidden" />в Encore Spa</AtomTitle>
      <div class="flex mt-20 md:mt-10 gap-x-3 md:gap-x-6">
        <AtomButton tag="button" class="input-file-btn" :data-yclients-url="yclients">ЗАПИСАТЬСЯ</AtomButton>
        <AtomButton v-if="whatsapp" :href="whatsapp" tag="a" class="input-file-btn">WHATSAPP</AtomButton>
      </div>
    </div>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'SpaFormSignUp',
  components: {
    AtomTitle,
    AtomButton,
  },
  builder: {
    component: 'spa-form-sign-up',
  },
  computed: {
    club() {
      return (
        this.$store.getters['modules/content/clubs'].find(c => c.slug === this.$route.params.id) || { spaTitle: '' }
      )
    },
    yclients() {
      return `${this.club.spaClient || 'https://n794050.yclients.com'}/widgetJS`
    },
    whatsapp() {
      return this.club.spaWhatsapp || null
    },
  },
}
</script>

<style lang="postcss" scoped></style>
