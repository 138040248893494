<template>
  <div class="pl-[10px] md:container">
    <AtomContent>
      <Scroll :mobile-only="true">
        <div class="flex max-w-[calc(100vw_-_10px)] pb-[20px] md:overflow-auto md:grid md:grid-cols-10 gap-x-5">
          <MoleculeCard v-for="card in cards" :key="card.name" class="md:col-span-4" :card="card" />
        </div>
      </Scroll>
    </AtomContent>
  </div>
</template>

<script>
import AtomContent from '../../atoms/AtomContent'
import MoleculeCard from '../../molecules/fitness/myzone/MoleculeCard'
import Scroll from '../../templates/layouts/Scroll'

export default {
  name: 'MyzoneCard',
  components: {
    AtomContent,
    MoleculeCard,
    Scroll,
  },
  builder: {
    component: 'myzone-cards',
  },
  data: () => {
    return {
      cards: [
        {
          title: 'Персональный тренинг с MYZONE®',
          image: 'fitness/f1-zoom.webp',
          imageMin: 'fitness/min/f1.webp',
          tag: [
            {
              name: 'Все тренировки',
            },
          ],
        },
        {
          title: 'Групповой тренинг с MYZONE®',
          image: 'fitness/f2-zoom.webp',
          imageMin: 'fitness/min/f2.webp',
          tag: [
            {
              name: 'MYZONE® HIIT',
            },
            {
              name: 'MYZONE® in the zone',
            },
          ],
        },
      ],
    }
  },
}
</script>
