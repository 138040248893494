<template>
  <div class="container">
    <div class="flex flex-col md:flex-row gap-5" :class="{ 'flex-col-reverse md:flex-row-reverse': type === 'right' }">
      <div class="md:w-4/12 flex flex-col gap-y-5 justify-between" :class="{ 'flex-col-reverse': type === 'right' }">
        <div class="flex items-center text-center h-full border-t border-b border-gray-100">
          <AtomTitle class="text-base md:text-lg py-2 md:py-0" tag="h3" :content="text" />
        </div>
        <AtomImageParallax
          class="w-full h-full overflow-hidden aspect-[296/128] md:aspect-[431/303]"
          img-classes="w-full"
          :strapi-image-object="smallImage"
        />
      </div>
      <div class="md:w-8/12">
        <AtomImageParallax
          class="club-img h-full w-full overflow-hidden aspect-[296/128] md:aspect-[648/540]"
          img-classes="w-full"
          :strapi-image-object="bigImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'AdvantageTwoImagesText',
  components: {
    AtomImageParallax,
    AtomTitle,
  },
  builder: {
    component: 'advantage-two-images-text',
  },
  props: ['text', 'smallImage', 'bigImage', 'type'],
}
</script>
