<template>
  <div
    class="flex flex-col pt-4 pb-4 md:pb-7 border-t border-b mt-[-1px] border-gray-500"
    :class="{ 'club-soon': item.openSoon }"
  >
    <div class="flex flex-col justify-between h-full">
      <nuxt-link :to="item.link">
        <AtomTitle tag="h3" :content="item.title" class="pb-4 uppercase" />
        <div class="aspect-[648/476] overflow-hidden">
          <AtomImageParallax
            v-if="item.strapiImageObject"
            class="club-img w-full min-h-[238px] aspect-[648/540]"
            :strapi-image-object="item.strapiImageObject"
          />
        </div>
      </nuxt-link>
      <div class="flex flex-col justify-between flex-1">
        <p class="text-sm md:text-base py-3 md:py-4 max-w-[520px] whitespace-pre-line" v-html="item.address" />
        <div class="flex flex-wrap gap-x-[6px]">
          <AtomButton
            v-for="control in item.controls"
            :key="control.title"
            :to="control.to"
            v-bind="control.dataset"
            :class="{ disabled: control.disabled }"
            :tel="control.tel"
            :tag="control.tag"
            :href="control.href"
            class="btn-sm btn-club"
            @click="control.action && control.action()"
            >{{ control.title }}</AtomButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomButton from '../atoms/AtomButton'
import AtomImageParallax from '../atoms/AtomImageParallax'
import AtomTitle from '../atoms/AtomTitle'

export default {
  name: 'MoleculeItem',
  components: {
    AtomTitle,
    AtomButton,
    AtomImageParallax,
  },
  props: ['item'],
}
</script>

<style lang="postcss" scoped>
.club-soon {
  h3,
  p {
    @apply text-gray-500;
  }
}
</style>
