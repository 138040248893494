<template>
  <div
    class="hidden md:flex flex-col justify-center items-end px-0 border-r border-gray-500 print:!border-b print:!flex print:!items-center"
  >
    <div class="text-base -rotate-90 print:text-sm">{{ time }}</div>
  </div>
</template>

<script>
export default {
  name: 'AtomTime',
  props: ['time'],
}
</script>
