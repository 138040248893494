<template>
  <div class="flex pr-[10px] md:pr-0 flex-row justify-between items-end border-b border-gray-100 mb-5">
    <AtomTitle :sup="sup" animation="rotation" class-name-sup="normal-case">{{ title }}</AtomTitle>
    <a class="mb-3 text-md md:text-xl" :href="rightLink" target="_blank">{{ rightTitle }}</a>
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'AtomMainFourImagesTitle',
  components: {
    AtomTitle,
  },
  builder: {
    component: 'main-four-images-title',
  },
  props: ['title', 'sup', 'rightTitle', 'rightLink'],
}
</script>

<style lang="postcss"></style>
