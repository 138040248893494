<template>
  <div class="grid grid-cols-12 gap-x-5 -md:hidden">
    <div class="col-span-2">
      <div ref="list" class="list">
        <span class="line" :style="lineStyles" />
        <div v-for="(link, i) in links" :key="i" :ref="link.hash">
          <AtomLink
            :to="{ hash: link.hash }"
            :content="link.title"
            :class="{ active: link.hash === id }"
            class="sidebar-link"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomLink from '../../atoms/AtomLink'

const MENU_ATTR_REF = 'data-sticky-menu-ref'
const MENU_ATTR_ENDING = 'data-sticky-menu-ending'
const MENU_ATTR_CONTAINER = 'data-sticky-menu-container'

export default {
  name: 'StickyMenu',
  components: {
    AtomLink,
  },
  props: ['links'],
  MENU_ATTR_REF,
  MENU_ATTR_CONTAINER,
  MENU_ATTR_ENDING,
  data() {
    return {
      id: null,
      boundaries: {
        bottom: 0,
        top: 0,
      },
    }
  },
  computed: {
    lineStyles() {
      if (!this.id || !this.mounted) return
      const rectActive = this.$refs[this.id][0].getBoundingClientRect()
      const rectList = this.$refs.list.getBoundingClientRect()
      const offset = rectActive.y - rectList.y
      return `height: ${rectActive.height}px; transform: translateY(${offset}px);`
    },
    hasPreloadScreen() {
      return this.$store.getters['entryLoaderScreen/hasPreloadScreen']
    },
  },
  watch: {
    '$route.hash'(hash) {
      this.scrollToHash(hash)
    },
    hasPreloadScreen() {
      if (this.$route.hash) {
        this.scrollToHash(this.$route.hash)
      }
    },
  },
  mounted() {
    this.mounted = true
    this.calcBoundaries()
    new ResizeObserver(() => {
      this.calcBoundaries()
      this.recalcOnFirstScroll = true
    }).observe(document.querySelector(`[${MENU_ATTR_CONTAINER}]`))
    new ResizeObserver(() => {
      this.calcBoundaries()
    }).observe(this.$el)
    this.$root.$loco.on('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.mounted = false
    this.$root.$loco.off('scroll', this.handleScroll)
  },
  methods: {
    scrollToHash(hash) {
      const MENU_HEIGHT = 70
      const menuRef = document.querySelector(`[${MENU_ATTR_REF}="${hash}"]`)
      this.$root.$loco.scrollTo(menuRef, { offset: -MENU_HEIGHT })
    },
    handleScroll(e) {
      if (!this.isDesktop() || !this.$refs.list) return
      const y = e.scroll.y
      if (this.recalcOnFirstScroll) {
        this.calcBoundaries(y)
        this.recalcOnFirstScroll = false
      }
      if (y < this.boundaries.top) {
        this.$refs.list.style.cssText = ''
        return
      }
      const offset = Math.min(y - this.boundaries.top, this.boundaries.bottom - this.boundaries.top)
      this.$refs.list.style.cssText = `transform: translateY(${offset}px)`
      this.markActiveMenuItem(y)
    },
    markActiveMenuItem(y) {
      const OFFSET = window.innerHeight * 0.2
      const { id } =
        this.refsLimits.find(ref => {
          return ref.y < y + OFFSET
        }) || this.refsLimits[this.refsLimits.length - 1]

      if (id !== this.id) {
        this.id = id
      }
    },
    calcBoundaries(y = this.$root.$loco.scroll.instance.delta?.y || 0) {
      if (!this.$el || !this.$refs.list) {
        if (this.mounted) setTimeout(this.calcBoundaries, 100)
        return
      }
      const topRect = getNodeRect(`[${MENU_ATTR_REF}="${this.links[0].hash}"]`)
      const bottomRect = getNodeRect(`[${MENU_ATTR_ENDING}]`)
      const listRect = this.$refs.list.getBoundingClientRect()
      const OFFSET = window.innerHeight * 0.2
      this.boundaries.top = y + topRect.y - OFFSET
      this.boundaries.bottom = y + bottomRect.y + bottomRect.height - listRect.height - 2 * OFFSET
      this.calcActiveMenuBoundaries(y)
      function getNodeRect(selector) {
        try {
          return document.querySelector(selector).getBoundingClientRect()
        } catch (e) {
          return { x: 0, y: 0, height: 0, width: 0 }
        }
      }
    },
    calcActiveMenuBoundaries(y) {
      this.refsLimits = [...document.querySelectorAll(`[${MENU_ATTR_REF}]`)]
        .map(ref => {
          const rect = ref.getBoundingClientRect()
          return { y: rect.y + y, id: ref.getAttribute(MENU_ATTR_REF) }
        })
        .reverse()
    },
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
  },
}
</script>

<style lang="postcss" scoped>
.line {
  @apply bg-gray-100 w-px h-full absolute left-0;
  transition: transform 0.5s cubic-bezier(0, 0, 0.34, 0.96), max-height 0.5s cubic-bezier(0, 0, 0.34, 0.96);
}
.list {
  @apply pl-4 col-span-2 flex flex-col absolute top-6 left-5 z-10 will-change-transform;
  &:before {
    @apply content-[''] w-px h-full absolute left-0 top-0 bg-repeat-y;
    background-image: url("data:image/svg+xml,%3Csvg width='1' height='4' viewBox='0 0 1 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1' height='1' fill='%23282929'/%3E%3C/svg%3E%0A");
  }
}
</style>
