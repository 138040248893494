import AtomDescDividesDesc from '../atoms/builder/AtomDescDividesDesc'
import AtomImage from '../atoms/builder/AtomImage'
import AtomMainFourImagesTitle from '../atoms/builder/AtomMainFourImagesTitle'
import AtomSpaFourImagesTitle from '../atoms/builder/AtomSpaFourImagesTitle'
import AtomText from '../atoms/builder/AtomText'
import AtomTextInlineLink from '../atoms/builder/AtomTextInlineLink'
import AtomTextHighlighted from '../atoms/builder/AtomTextHighlighted'
import AtomTextImage from '../atoms/builder/AtomTextImage'
import AtomTitle from '../atoms/builder/AtomTitle'
import AtomTitleDividesTitle from '../atoms/builder/AtomTitleDividesTitle'
import AtomTitleSimple from '../atoms/builder/AtomTitleSimple'
import AtomVerticalLineText from '../atoms/builder/AtomVerticalLineText'

export const TYPES_ATOM = {
  [AtomText.builder.component]: AtomText.name,
  [AtomVerticalLineText.builder.component]: AtomVerticalLineText.name,
  [AtomTextImage.builder.component]: AtomTextImage.name,
  [AtomTitleDividesTitle.builder.component]: AtomTitleDividesTitle.name,
  [AtomDescDividesDesc.builder.component]: AtomDescDividesDesc.name,
  [AtomTitleSimple.builder.component]: AtomTitleSimple.name,
  [AtomTitle.builder.component]: AtomTitle.name,
  [AtomSpaFourImagesTitle.builder.component]: AtomSpaFourImagesTitle.name,
  [AtomMainFourImagesTitle.builder.component]: AtomMainFourImagesTitle.name,
  [AtomImage.builder.component]: AtomImage.name,
  [AtomTextHighlighted.builder.component]: AtomTextHighlighted.name,
  [AtomTextInlineLink.builder.component]: AtomTextInlineLink.name,
}

export const componentsAtom = {
  [AtomText.name]: AtomText,
  [AtomVerticalLineText.name]: AtomVerticalLineText,
  [AtomTextImage.name]: AtomTextImage,
  [AtomTitleDividesTitle.name]: AtomTitleDividesTitle,
  [AtomDescDividesDesc.name]: AtomDescDividesDesc,
  [AtomTitleSimple.name]: AtomTitleSimple,
  [AtomTitle.name]: AtomTitle,
  [AtomSpaFourImagesTitle.name]: AtomSpaFourImagesTitle,
  [AtomMainFourImagesTitle.name]: AtomMainFourImagesTitle,
  [AtomImage.name]: AtomImage,
  [AtomTextHighlighted.name]: AtomTextHighlighted,
  [AtomTextInlineLink.name]: AtomTextInlineLink,
}
