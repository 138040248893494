<template>
  <div
    class="flex pr-[10px] md:pr-0 flex-row justify-between items-end border-b border-gray-100 mb-5 mx-3 md:mx-5 pb-2"
  >
    <div class="md:grid grid-cols-12 w-full">
      <div class="col-start-3 col-span-10">
        <a href="https://www.instagram.com/encore.spa/" target="_blank">
          <AtomTitle :sup="sup" tag="h2" animation="rotation" class="inline-flex" class-name-sup="normal-case">{{
            title
          }}</AtomTitle>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'AtomSpaFourImagesTitle',
  components: {
    AtomTitle,
  },
  builder: {
    component: 'spa-four-images-title',
  },
  props: ['title', 'sup'],
}
</script>

<style lang="postcss"></style>
