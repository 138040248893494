<template>
  <div class="flex flex-wrap gap-3 md:gap-2">
    <AtomTag v-for="item in tags" :key="item.name" :content="item.name" tag="span" class="bg-white !border-white" />
  </div>
</template>

<script>
import AtomTag from '../../atoms/AtomTag'

export default {
  name: 'MoleculeAppsTags',
  components: {
    AtomTag,
  },
  props: ['tags'],
}
</script>
