<template>
  <div
    v-if="item && item.title && item.progress"
    class="flex flex-col md:grid grid-cols-7 md:gap-x-4 mb-8 md:items-center"
  >
    <p class="col-span-2 -md:text-sm -md:mb-1">{{ item.title }}</p>
    <div class="col-span-5 flex w-full">
      <AtomProgress :progress="item.progress" />
    </div>
  </div>
</template>

<script>
import AtomProgress from '../../atoms/AtomProgress'

export default {
  name: 'MoleculeModalRowProgress',
  components: {
    AtomProgress,
  },
  props: ['item'],
}
</script>
