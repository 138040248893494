import MoleculeAdvantageTwoImagesText from '../molecules/builder/AdvantageTwoImagesText'
import MoleculeClubButtonsDownload from '../molecules/builder/ClubButtonsDownload'
import MoleculeFourImages from '../molecules/builder/FourImages'
import MoleculeGallery from '../molecules/builder/Gallery'
import MoleculeRunningLine from '../molecules/builder/RunningLine'
import MoleculeSlider from '../molecules/builder/Slider'
import MoleculeSpaFormSignUp from '../molecules/builder/SpaFormSignUp'

export const TYPES_MOLECULE = {
  [MoleculeSlider.builder.component]: MoleculeSlider.name,
  [MoleculeGallery.builder.component]: MoleculeGallery.name,
  [MoleculeFourImages.builder.component]: MoleculeFourImages.name,
  [MoleculeSpaFormSignUp.builder.component]: MoleculeSpaFormSignUp.name,
  [MoleculeClubButtonsDownload.builder.component]: MoleculeClubButtonsDownload.name,
  [MoleculeAdvantageTwoImagesText.builder.component]: MoleculeAdvantageTwoImagesText.name,
  [MoleculeRunningLine.builder.component]: MoleculeRunningLine.name,
}

export const componentsMolecule = {
  [MoleculeSlider.name]: MoleculeSlider,
  [MoleculeGallery.name]: MoleculeGallery,
  [MoleculeFourImages.name]: MoleculeFourImages,
  [MoleculeSpaFormSignUp.name]: MoleculeSpaFormSignUp,
  [MoleculeClubButtonsDownload.name]: MoleculeClubButtonsDownload,
  [MoleculeAdvantageTwoImagesText.name]: MoleculeAdvantageTwoImagesText,
  [MoleculeRunningLine.name]: MoleculeRunningLine,
}
