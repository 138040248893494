<template>
  <div class="grid md:grid-cols-10 gap-x-16">
    <div class="md:col-span-7 md:border-t border-gray-500 pt-5 -md:order-2">
      <AtomTitle tag="h2" :content="title" />
      <div class="md:grid grid-cols-7">
        <div class="md:col-span-6">
          <AtomText :content="description" class="py-3 md:pt-10" />
        </div>
      </div>
    </div>
    <div class="md:col-span-3 -md:order-1">
      <AtomImageParallax
        class="w-full max-h-[300px] md:max-h-[700px]"
        img-classes="object-cover object-top"
        :strapi-image-object="image"
      />
    </div>
  </div>
</template>

<script>
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'OrganismTitleImage',
  builder: {
    component: 'title-image',
  },
  components: {
    AtomTitle,
    AtomText,
    AtomImageParallax,
  },
  props: ['title', 'description', 'image'],
}
</script>
