<template>
  <div class="container">
    <AtomImageParallax
      class="mt-3 md:mt-8 min-h-[238px] aspect-[296/238] md:aspect-[1330/606]"
      :strapi-image-object="image"
    />
  </div>
</template>

<script>
import AtomImageParallax from '../AtomImageParallax'

export default {
  name: 'BuilderAtomImage',
  builder: {
    component: 'image',
  },
  components: {
    AtomImageParallax,
  },
  props: ['image'],
}
</script>
