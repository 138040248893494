<template>
  <div class="flex max-w-[100vw] overflow-auto md:grid md:grid-cols-4 gap-3 md:gap-5 mx-3 md:mx-5">
    <a
      v-for="(image, i) in images"
      :key="i"
      target="_blank"
      class="inline-flex min-w-[75%] w-9/12 md:w-full md:mt-[-1px]"
    >
      <div
        data-scroll
        :data-scroll-class="`veil-animation-${i + 1}`"
        data-scroll-offset="30%,30%"
        class="veil-animation w-full"
      >
        <AtomImageParallax
          class="aspect-[316/307]"
          :src="typeof image === 'string' && image"
          :strapi-image-object="typeof image !== 'string' && image"
        />
      </div>
    </a>
  </div>
</template>

<script>
import AtomImageParallax from '../../atoms/AtomImageParallax'

export default {
  name: 'FourImages',
  components: {
    AtomImageParallax,
  },
  builder: {
    component: 'four-images',
  },
  props: ['image1', 'image2', 'image3', 'image4'],
  computed: {
    images() {
      return [this.image1, this.image2, this.image3, this.image4].filter(Boolean)
    },
  },
}
</script>

<style lang="postcss" scoped>
.veil-animation {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    content: '';
    background: white;
    display: block;
    z-index: 1;
    transform: translateY(0);
    transition: transform 1.3s var(--cubic-bezier) 0.8s;
  }
}
.veil-animation-1:before {
  transform: translateY(-100%);
  transition: transform 1s var(--cubic-bezier);
}
.veil-animation-2:before {
  transform: translateY(-100%);
  transition: transform 0.9s var(--cubic-bezier) 0.1s;
}
.veil-animation-3:before {
  transform: translateY(-100%);
  transition: transform 0.8s var(--cubic-bezier) 0.2s;
}
.veil-animation-4:before {
  transform: translateY(-100%);
  transition: transform 0.7s var(--cubic-bezier) 0.3s;
}
.veil-animation-5:before {
  transform: translateY(-100%);
  transition: transform 0.6s var(--cubic-bezier) 0.4s;
}
.veil-animation-6:before {
  transform: translateY(-100%);
  transition: transform 0.5s var(--cubic-bezier) 0.5s;
}
.veil-animation-7:before {
  transform: translateY(-100%);
  transition: transform 0.4s var(--cubic-bezier) 0.6s;
}
.veil-animation-8:before {
  transform: translateY(-100%);
  transition: transform 0.3s var(--cubic-bezier) 0.7s;
}
</style>
