<template>
  <label class="relative inline-block input-file">
    <input
      type="file"
      name="file"
      class="absolute opacity-0 block w-0 h-0 -z-10"
      accept=".pdf,.doc,.docx"
      @input="change"
    />
    <AtomButton tag="span" class="btn-sm input-file-btn">Прикрепить резюме</AtomButton>
    <span class="input-file-text">.PDF или .DOC до 10мб</span>
    <div v-if="name" class="pt-2">{{ name }}</div>
  </label>
</template>

<script>
import AtomButton from '../AtomButton'

export default {
  name: 'AtomInputFile',
  components: {
    AtomButton,
  },
  data() {
    return {
      name: null,
    }
  },
  methods: {
    change({ target }) {
      try {
        this.name = target.files[0].name
      } catch (e) {
        this.name = null
      }
      this.$emit('change', (target.files || [])[0])
    },
  },
}
</script>

<style lang="postcss" scoped>
.input-file-text {
  padding: 0 10px;
  line-height: 40px;
  display: inline-block;
}
</style>
