<template>
  <div>{{ content }}</div>
</template>

<script>
export default {
  name: 'AtomText',
  props: ['content'],
}
</script>
