<template>
  <div
    class="flex -md:flex-col -md:items-start -md:gap-y-3 md:justify-end md:gap-x-4 px-3 md:px-5 mb-24 md:mb-48 mt-3 md:mt-5"
  >
    <AtomButton v-for="item in buttons" :key="item.title" class="btn-sm" tag="a" :href="item.link">{{
      item.text
    }}</AtomButton>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'

export default {
  name: 'MoleculeClubButtonsDownload',
  components: {
    AtomButton,
  },
  builder: {
    component: 'club-buttons-download',
  },
  props: ['buttons'],
}
</script>

<style lang="postcss" scoped></style>
