<template>
  <ul class="list-disc pl-5 text-sm md:text-base">
    <li v-for="item in list" :key="item.name">{{ item.text }}</li>
  </ul>
</template>

<script>
export default {
  name: 'AtomList',
  props: ['list'],
}
</script>

<style lang="postcss"></style>
