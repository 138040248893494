<template>
  <div
    class="cell flex flex-col min-h-[140px] md:min-h-[190px] justify-between w-full relative p-2 border border-t mb-[-1px] border-gray-500 print:!border-l-0 print:!border-r-0 print:first:!border-b print:min-h-[130px]"
    :class="{ '!border-l bg-gray-600 print:!bg-transparent': timetable.today, 'old-day': timetable.old }"
    @click="$emit('click')"
  >
    <div>
      <div class="flex justify-between min-h-[18px]">
        <AtomText class="category text-sm text-gray-400" :content="timetable.categoryType" />
        <div class="flex">
          <template v-if="timetable.paid">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.42188 9.85508C5.42188 10.6613 6.04063 11.3113 6.80938 11.3113H8.37812C9.04687 11.3113 9.59062 10.7426 9.59062 10.0426C9.59062 9.28008 9.25937 9.01133 8.76562 8.83633L6.24687 7.96133C5.75312 7.78633 5.42188 7.51758 5.42188 6.75508C5.42188 6.05508 5.96562 5.48633 6.63437 5.48633H8.20312C8.97187 5.48633 9.59062 6.13633 9.59062 6.94258"
                stroke="#282929"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M7.5 4.64893V12.1489" stroke="#282929" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7.5 14.6489C10.9518 14.6489 13.75 11.8507 13.75 8.39893C13.75 4.94715 10.9518 2.14893 7.5 2.14893C4.04822 2.14893 1.25 4.94715 1.25 8.39893C1.25 11.8507 4.04822 14.6489 7.5 14.6489Z"
                stroke="#282929"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
          <template v-if="timetable.meet">
            <svg class="ml-1" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 8.02979H9.375"
                stroke="#282929"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 10.5298H7.7375"
                stroke="#282929"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.25 4.15479H8.75C10 4.15479 10 3.52979 10 2.90479C10 1.65479 9.375 1.65479 8.75 1.65479H6.25C5.625 1.65479 5 1.65479 5 2.90479C5 4.15479 5.625 4.15479 6.25 4.15479Z"
                stroke="#282929"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 2.9173C12.0812 3.0298 13.125 3.79855 13.125 6.6548V10.4048C13.125 12.9048 12.5 14.1548 9.375 14.1548H5.625C2.5 14.1548 1.875 12.9048 1.875 10.4048V6.6548C1.875 3.8048 2.91875 3.0298 5 2.9173"
                stroke="#282929"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
        </div>
      </div>
      <AtomTitle tag="h3" class="time print:text-[10px] print:hidden" :content="timetable.time" />
      <AtomText
        class="name text-base line-clamp-3 print:text-[10px] print:font-medium print:line-clamp-2"
        :content="timetable.name"
      />
    </div>
    <div>
      <AtomText
        class="trainer text-sm text-gray-400 print:text-[10px]"
        :content="`${timetable.trainer.name} ${timetable.trainer.surname}`"
      />
      <AtomText
        class="place text-sm text-gray-400 print:text-[8px] print:line-clamp-3"
        :content="timetable.place.name"
      />
    </div>
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'
import AtomText from '../../atoms/timetable/AtomText'

export default {
  name: 'MoleculeDay',
  components: {
    AtomTitle,
    AtomText,
  },
  props: ['timetable'],
}
</script>

<style lang="postcss" scoped>
.cell {
  @apply cursor-pointer;
  &:hover {
    background: linear-gradient(180deg, #eff0f4 0%, rgba(239, 240, 244, 0) 100%);
  }
  &.old-day {
    svg path {
      stroke: #cfd1d6;
      @apply print:stroke-gray-100;
    }
    .category,
    .trainer,
    .place {
      @apply opacity-50 print:opacity-100;
    }
    .time,
    .name {
      @apply text-gray-500 print:text-gray-100;
    }
  }
}
</style>
