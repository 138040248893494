<template>
  <div class="gallery">
    <client-only>
      <swiper v-if="isLoop" ref="swiper" :options="options" @slideChange="onChange" @slideChangeTransitionEnd="loopFix">
        <swiper-slide v-for="(slide, i) in item" :key="i">
          <h2 class="inline-block text-black">{{ slide.title }}</h2>
        </swiper-slide>
      </swiper>
      <div v-if="!isLoop" class="swiper-container">
        <div class="swiper-wrapper pl-3 md:pl-[18%]">
          <div
            v-for="(slide, i) in item"
            :key="i"
            class="swiper-slide"
            :class="{ ['swiper-slide-active']: i === tabIndex }"
            @click="changeTab(i)"
          >
            <h2 class="inline-block text-black">{{ slide.title }}</h2>
          </div>
        </div>
      </div>
      <div class="container" @click="swipeArrors" @swiped-left="nextSlide" @swiped-right="prevSlide">
        <AtomContent>
          <div class="grid grid-cols-10 gap-x-5 pt-5">
            <AtomText class="-md:order-2 col-span-10 md:col-span-3" :content="getSlide().description" />
            <figure class="-md:order-1 -md:mb-2 col-span-10 md:col-span-7 relative aspect-[758/566]">
              <AtomImage
                v-if="images[0]"
                :class="{ active: activeImage === 0 }"
                :strapi-image-object="images[0]"
                class="gallery-img w-full"
              />
              <AtomImage
                v-if="images[1]"
                :class="{ active: activeImage === 1 }"
                :strapi-image-object="images[1]"
                class="gallery-img w-full"
              />
            </figure>
          </div>
        </AtomContent>
      </div>
    </client-only>
  </div>
</template>

<script>
import AtomContent from '../../atoms/AtomContent'
import AtomImage from '../../atoms/AtomImage'
import AtomText from '../../atoms/AtomText'

export default {
  name: 'Gallery',
  components: {
    AtomImage,
    AtomContent,
    AtomText,
  },
  builder: {
    component: 'gallery',
  },
  props: ['item', 'type'],
  data() {
    return {
      images: [],
      activeImage: 1,
      tabIndex: 0,
      options: {
        slidesPerView: 'auto',
        speed: 1000,
        loop: true,
        slideToClickedSlide: true,
      },
    }
  },
  computed: {
    isLoop() {
      return this.type === 'loop'
    },
  },
  beforeMount() {
    const WIDTH_HEADER = 75
    const FONT_SHIFT = 3
    this.options.shortSwipes = window.innerWidth < 960
    this.options.slidesOffsetBefore = this.$device.isDesktop
      ? (window.innerWidth - WIDTH_HEADER) * 0.18 - FONT_SHIFT
      : 12 + FONT_SHIFT
  },
  mounted() {
    this.setActiveImage()
  },
  methods: {
    swipeArrors(e) {
      if (!this.$device.isDesktop) return
      const box = e.currentTarget.getBoundingClientRect()
      if (box.x + box.width / 2 > e.x) {
        this.prevSlide()
      } else {
        this.nextSlide()
      }
    },
    nextSlide() {
      this.$refs.swiper.swiperInstance.slideNext()
    },
    prevSlide() {
      this.$refs.swiper.swiperInstance.slidePrev()
    },
    changeTab(index) {
      this.tabIndex = index
      this.setActiveImage()
    },
    getSlide() {
      if (!this.isLoop) {
        return this.item[this.tabIndex]
      }
      if (!this.$refs.swiper) return this.item[0]
      const index = this.$refs.swiper.$swiper.realIndex
      return this.item[index]
    },
    onChange() {
      this.setActiveImage()
    },
    loopFix() {
      if (!this.$refs.swiper.options.loop) return
      const node = this.$refs.swiper.$el
      node.classList.add('loop-fix')
      this.$refs.swiper.$swiper.loopFix()
      setTimeout(() => {
        node.classList.remove('loop-fix')
      })
    },
    setActiveImage() {
      this.activeImage = (this.activeImage + 1) % 2
      this.$set(this.images, this.activeImage, this.getSlide().image)
    },
  },
}
</script>

<style lang="postcss">
.gallery {
  .container {
    @apply relative;
    cursor: url("data:image/svg+xml,%3Csvg width='101' height='47' viewBox='0 0 101 47' fill='none' style='transform: rotate(180deg)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.7881 23.3809L76.4258 1.01862M98.7881 23.3809L76.1017 46.0672M98.7881 23.3809L0.000974143 23.3812' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A")
        50 23,
      pointer;
    &::after {
      z-index: 1;
      content: '';
      cursor: url("data:image/svg+xml,%3Csvg width='101' height='47' viewBox='0 0 101 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.7881 23.3809L76.4258 1.01862M98.7881 23.3809L76.1017 46.0672M98.7881 23.3809L0.000974143 23.3812' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A")
          50 23,
        pointer;
      @apply absolute inset-y-0 right-0 w-[50%];
    }
  }
  .swiper-container {
    &::before {
      content: '';
      @apply absolute bottom-[1px] h-[1px] block bg-repeat-x w-full;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' height='1' viewBox='0 0 8 1' width='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 .5h8' stroke='%23282929' stroke-dasharray='1 3'/%3E%3C/svg%3E");
    }
  }
  .swiper-wrapper {
    transition-timing-function: var(--cubic-bezier);
  }
  .swiper-slide {
    width: auto;
    cursor: pointer;
    @apply text-gray-500 mr-5 md:mr-10 lg:mr-14 pb-3;
    &::before {
      content: '';
      transition: width 0.8s var(--cubic-bezier);
      @apply absolute bottom-0 h-[3px] block bg-gray-100 w-0 rounded-full;
    }
    &.swiper-slide-active {
      @apply text-gray-100;
      &::before {
        @apply w-full;
      }
    }
  }
  .gallery-img {
    @apply opacity-0 absolute inset-0 h-full object-cover;
    transition: opacity 1s var(--cubic-bezier);
    &.active {
      @apply opacity-100;
    }
  }
  .loop-fix {
    .swiper-slide {
      &::before {
        transition: none;
      }
    }
  }
}
</style>
