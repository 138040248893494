<template>
  <div ref="holder" class="overflow-hidden" data-scroll data-scroll-target="[data-scroll-container]">
    <div
      class="image-container h-full relative"
      :class="{ [imgWrapClasses || '']: true }"
      :data-scroll-id="id"
      :data-scroll-offset="offset"
      :data-scroll-speed="speed"
      data-scroll
      data-scroll-class="expand"
    >
      <AtomImage
        ref="image"
        class="h-full scale-125 w-full object-cover"
        :class="{ [imgClasses || '']: true }"
        :width="width"
        :height="height"
        :preset="preset"
        :src="src"
        :strapi-image-object="strapiImageObject"
      />
    </div>
  </div>
</template>

<script>
import AtomImage from './AtomImage'
let id = 0

export default {
  name: 'AtomImageParallax',
  components: {
    AtomImage,
  },
  props: ['src', 'width', 'height', 'preset', 'imgClasses', 'imgWrapClasses', 'strapiImageObject'],
  data() {
    return {
      speed: -1.5,
      offset: '30%,30%',
      id: `parallax_${++id}`,
    }
  },
  mounted() {
    const offset = Math.floor((window.innerWidth / window.innerHeight) * 8)
    this.offset = `${offset}%,${offset}%`
    if (!this.isDesktop()) {
      const holderRect = this.$refs.holder.getBoundingClientRect()
      const imageRect = this.$refs.image.$el.getBoundingClientRect()
      this.limit = (imageRect.height - holderRect.height) / 2 || 0
      this.$root.$loco.on('scroll', this.handleScroll)
    }
  },
  beforeDestroy() {
    if (!this.isDesktop()) {
      this.$root.$loco.off('scroll', this.handleScroll)
    }
  },
  methods: {
    isDesktop() {
      return Boolean(this.$root.$loco && !this.$root.$loco.scroll.isMobile && !this.$root.$loco.scroll.isTablet)
    },
    mobileSetParallax(data) {
      const img = data.currentElements[this.id]
      if (img) {
        const top = Math.max(Math.min((0.5 - img.progress) * 60 * this.speed, this.limit), -this.limit)
        img.el.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${top}, 0, 1)`
      }
    },
    handleScroll(data) {
      this.mobileSetParallax(data)
    },
  },
}
</script>

<style lang="postcss" scoped>
/* .image-container {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: white;
    transition: transform 1s var(--cubic-bezier);
  }
} */
.expand {
  &:before {
    transform: translateY(-100%);
  }
}
</style>
