<template>
  <div>
    <div class="border-t border-gray-500 pt-4 md:pt-10 relative">
      <div class="title">
        <AtomTitle class="-md:mb-3" tag="h1" :content="row.date" />
        <AtomImage
          class="-md:hidden image aspect-[314/244] max-w-[244px] absolute opacity-0 object-cover right-0 top-[-60px] z-[-1]"
          :src="row.image"
        />
      </div>
      <AtomText :content="row.text" />
    </div>
  </div>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'

export default {
  name: 'MoleculeEvolution',
  components: {
    AtomImage,
    AtomTitle,
    AtomText,
  },
  props: ['row'],
}
</script>

<style lang="postcss" scoped>
.title {
  .image {
    transition: opacity 0.5s var(--cubic-bezier);
  }
  &:hover {
    .image {
      @apply opacity-100;
    }
  }
}
</style>
