<template>
  <div>
    <AtomTitle
      tag="h2"
      class="md:col-span-5 mb-4 md:mb-10"
      :class="{ ['hidden']: !flip }"
      :content="title"
      animation="rotation"
    />
    <div class="grid md:grid-cols-10 gap-x-5">
      <div class="md:col-span-4" :class="{ ['order-2']: !flip }">
        <div class="lg:grid grid-cols-5">
          <div class="paragraph-list md:col-span-5" :class="{ ['flip']: flip }">
            <AtomImageParallax
              class="md:hidden w-full max-h-[700px] mt-4 order-2"
              img-classes="object-cover object-top"
              :strapi-image-object="image"
            />
            <AtomTitle
              tag="h2"
              class="-md:mb-0 -md:mt-3 md:col-span-5 mb-4 md:mb-10"
              :class="{ ['hidden']: flip }"
              :content="title"
              animation="rotation"
            />
            <AtomText
              v-for="i in description"
              :key="i.name"
              class="paragraph py-3 md:py-10"
              :description="i.description"
            />
          </div>
        </div>
      </div>
      <div class="md:col-span-5" :class="{ ['order-1 pr-12']: !flip, ['md:col-start-6']: flip }">
        <AtomImageParallax
          class="w-full max-h-[700px] -md:hidden"
          img-classes="object-cover object-top"
          :strapi-image-object="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomTitle from '../../atoms/AtomTitle'
import AtomText from '../../atoms/builder/AtomText'

export default {
  name: 'OrganismBoxImageTwoCol',
  builder: {
    component: 'two-column-image',
    layout: 'gap-content',
  },
  components: {
    AtomTitle,
    AtomText,
    AtomImageParallax,
  },
  props: ['description', 'title', 'image', 'flip'],
}
</script>

<style lang="postcss" scoped>
.paragraph {
  @apply border-b border-gray-500 last:border-0;
}
.paragraph-list {
  &.flip {
    @apply flex flex-col;
    .paragraph {
      &:nth-child(3) {
        @apply -md:order-1 -md:border-0 -md:py-0;
      }
      &:nth-child(4) {
        @apply order-3 -md:border-t -md:mt-4;
      }
      &:nth-child(5) {
        @apply order-4;
      }
    }
  }
  .paragraph {
    &:nth-child(3) {
      @apply pt-0 md:pt-0;
    }
  }
}
</style>
