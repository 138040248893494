<template>
  <div class="overflow-hidden">
    <div class="flex flex-col overflow-hidden w-screen md:w-[calc(100vw_-_75px)] px-3 md:px-5">
      <div class="border-y border-gray-100">
        <div class="flex flex-row w-full uppercase whitespace-nowrap py-3 md:py-10 mx-[-12px] md:mx-[-20px]">
          <AtomTitleMarquee
            v-for="i in 3"
            :key="i"
            :class="`animate-title-${direction || 'left'}-${i} text-2xl uppercase`"
            :content="`&nbsp;${text}&nbsp;&#8226;`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitleMarquee from '../../atoms/AtomTitleMarquee'

export default {
  name: 'RunningLine',
  components: {
    AtomTitleMarquee,
  },
  builder: {
    component: 'running-line',
  },
  props: ['text', 'direction'],
}
</script>

<style lang="postcss" scoped>
.animate-title-left-1 {
  display: inline-block;
  animation: scrolling-left-1 20s linear infinite;
}

.animate-title-left-2 {
  display: inline-block;
  animation: scrolling-left-2 20s linear infinite;
  animation-delay: 10s;
}

.animate-title-left-3 {
  display: inline-block;
  animation: scrolling-left-3 20s linear infinite;
  animation-delay: 10s;
}

@keyframes scrolling-left-1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrolling-left-2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes scrolling-left-3 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-300%);
  }
}

.animate-title-right-1 {
  display: inline-block;
  animation: scrolling-right-1 20s linear infinite;
}

.animate-title-right-2 {
  display: inline-block;
  animation: scrolling-right-2 20s linear infinite;
  animation-delay: 0s;
}

.animate-title-right-3 {
  display: inline-block;
  animation: scrolling-right-3 20s linear infinite;
  animation-delay: 0s;
}

@keyframes scrolling-right-1 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes scrolling-right-2 {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes scrolling-right-3 {
  0% {
    transform: translateX(-400%);
  }
  100% {
    transform: translateX(-200%);
  }
}
</style>
