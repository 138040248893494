import Blank from './core/Blank'
import Layout from './core/Layout'
import StickyMenu from './core/StickyMenu'
import StickyMenuRef from './core/StickyMenuRef'

export { StickyMenu }

export const TYPES_CORE = {
  [StickyMenuRef.builder.component]: StickyMenuRef.name,
}

export const componentsCore = {
  [Layout.name]: Layout,
  [Blank.name]: Blank,
  [StickyMenu.name]: StickyMenu,
  [StickyMenuRef.name]: StickyMenuRef,
}
