<template>
  <div class="text-sm md:text-base" :class="className">
    <MarkdownLinkNavigation>
      <div v-if="description" class="wrap-text" v-html="description" />
    </MarkdownLinkNavigation>
  </div>
</template>

<script>
import MarkdownLinkNavigation from '../../builder/core/MarkdownLinkNavigation'

export default {
  name: 'AtomText',
  components: {
    MarkdownLinkNavigation,
  },
  builder: {
    component: 'text',
  },
  props: ['description', 'className'],
}
</script>
