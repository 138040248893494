<template>
  <div class="container mt-20 mb-24 md:mb-44 md:mt-44">
    <AtomContent>
      <div v-for="(item, i) in data" :key="item.name" class="row md:grid grid-cols-10">
        <div v-if="!i" class="col-span-4 -md:mb-4">
          <AtomTitle tag="h2" :content="title" />
        </div>
        <div class="col-span-4 item -md:mb-10">
          <MoleculeEvolution :row="item" />
        </div>
      </div>
    </AtomContent>
  </div>
</template>

<script>
import AtomContent from '../../atoms/AtomContent'
import AtomTitle from '../../atoms/AtomTitle'
import MoleculeEvolution from '../../molecules/about/MoleculeEvolution'

export default {
  name: 'OrganismAboutEvolution',
  components: {
    AtomContent,
    AtomTitle,
    MoleculeEvolution,
  },
  builder: {
    component: 'about-evolution',
  },
  data: () => {
    return {
      title: 'Эволюция Encore Fitness',
      data: [
        {
          date: '2016',
          text: 'Открылся первый флагман сети площадью 3&nbsp;900&nbsp;кв.&nbsp;м&nbsp;в&nbsp;сентябре 2016 года в&nbsp;небоскребе ОКО и&nbsp;стал самым большим фитнес-клубом в&nbsp;Москва-Сити. На&nbsp;территории клуба расположились 9&nbsp;фитнес-зон, включая зону функционального тренинга, студию баланса и&nbsp;студию пилатеса, а&nbsp;также 25-метровый бассейн.',
          image: 'about/evolution/1.webp',
        },
        {
          date: '2017',
          text: 'Второй клуб Encore открылся в&nbsp;мае 2017 года в&nbsp;новом премиальном жилом комплексе Новоясеневский на&nbsp;Юго-Западе Москвы. Пространство площадью 2&nbsp;200&nbsp;кв.&nbsp;м&nbsp;включает в&nbsp;себя 7&nbsp;фитнес-зон, в&nbsp;том числе большой современный детский клуб и&nbsp;бассейн уникальной каплевидной формы.',
          image: 'about/evolution/2.webp',
        },
        {
          date: '2020',
          text: 'Летом 2020 года на&nbsp;Ходынском поле открыл свои двери самый большой клуб Encore Fitness. Проект удивляет исключительным дизайном, просторным Детским клубом, 25-метровым бассейном с&nbsp;мультимедийными инсталляциями и&nbsp;роскошным термальным комплексом.',
          image: 'about/evolution/3.webp',
        },
        {
          date: '2021',
          text: 'В&nbsp;конце 2021 года открылся первый клуб Encore в&nbsp;Санкт Петербурге в&nbsp;самом центре Васильевского острова.',
          image: 'about/evolution/4.webp',
        },
        {
          date: '2022',
          text: 'Летом 2022 года открылся проект на&nbsp;трёх этажах самой высокой башни Урала Iset Tower. Изысканное пространство площадью 3&nbsp;000 кв.м.&nbsp;с&nbsp;панорамными окнами по&nbsp;всему периметру клуба предлагает насладиться возможностями сразу 7&nbsp;фитнес-зон, которые органично дополняют термальный комплекс для отдыха и&nbsp;релакса, а&nbsp;также многофункциональный СПА с&nbsp;самыми эффективными методиками по&nbsp;эстетике лица и&nbsp;тела.',
          image: 'about/evolution/5.jpg',
        },
        {
          date: '2023',
          text: 'Весной 2023 года запустился первый премиальный фитнес-клуб в&nbsp;Сочи в&nbsp;ЖК&nbsp;&laquo;Сан-Сити&raquo;. Лаконичный интерьер в&nbsp;стиле контемпорари, последние линейки тренажёров и&nbsp;сервис высочайшего уровня оставляют незабываемые впечатления. Место притяжения всех резидентов клуба&nbsp;&mdash; 25-метровый бассейн с&nbsp;гидромассажем и&nbsp;термальным комплексом для парений на&nbsp;любой вкус. Приятным дополнением станет открытая терраса с&nbsp;видом на&nbsp;море, просторной лаундж-зоной и&nbsp;бочками-саунами.',
          image: 'about/evolution/6.jpg',
        },
      ],
    }
  },
}
</script>

<style lang="postcss" scoped>
.row {
  &:nth-child(odd) {
    .item {
      @apply md:col-start-6;
    }
  }
}
</style>
