<template>
  <div class="container">
    <AtomTitle class="mb-3" :sup="`(${clubs.length})`" animation="rotation">Клубы</AtomTitle>
    <div class="clubs">
      <MoleculeItemRow
        v-for="club in limitClubs"
        :key="club.slug"
        class="md:even:flex-row-reverse h-full"
        :club="club"
      />
      <MoleculeControlList :control="control" />
    </div>
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'
import MoleculeControlList from '../../molecules/clubs/controlList'
import MoleculeItemRow from '../../molecules/clubs/itemRow'

export default {
  name: 'MainClubs',
  builder: {
    component: 'main-clubs',
  },
  components: {
    AtomTitle,
    MoleculeControlList,
    MoleculeItemRow,
  },
  data: () => {
    return {
      clubsToShow: 3,
    }
  },
  computed: {
    limitClubs() {
      return this.clubs.slice(0, this.clubsToShow)
    },
    clubs() {
      return this.$store.getters['modules/content/clubs']
    },
    control() {
      return {
        title: 'ВСЕ КЛУБЫ',
        titleSup: '+' + (this.clubs.length - this.clubsToShow),
        button: 'БОЛЬШЕ...',
        to: '/clubs',
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.clubs {
  &:hover {
    ::v-deep .club-video {
      @apply opacity-100;
    }
  }
}
</style>
