<template>
  <div class="container">
    <Accordion class="border-y border-gray-500">
      <AccordionItem v-for="club in clubs" :key="club.id" class="!py-0">
        <template slot="accordion-trigger">
          <AtomTitle
            class="uppercase py-6"
            tag="h2"
            class-name="-md:flex"
            class-name-sup="md:!text-base md:!top-2 -md:!left-2 md:!left-0 -md:leading-[130%] -md:!top-0"
            >{{ club.title }}</AtomTitle
          >
        </template>
        <template slot="accordion-content">
          <div class="flex flex-1 flex-col justify-between contacts-club">
            <div class="w-full">
              <div class="md:grid grid-cols-2">
                <div class="flex flex-col flex-1 justify-between border-t border-gray-500">
                  <div>
                    <client-only>
                      <div
                        v-for="contact in getContactsWithoutAddress(club)"
                        :key="contact.id"
                        class="md:grid grid-cols-3 border-b border-gray-500 py-4 md:mr-5 gap-x-3 md:gap-x-5"
                      >
                        <AtomText :content="contact.col1" />
                        <div class="grid grid-cols-2 col-span-2 -md:mt-2">
                          <AtomText :content="contact.col2" />
                          <AtomText :content="contact.col3" />
                          <AtomText v-if="contact.col4" :content="contact.col4" />
                        </div>
                      </div>
                    </client-only>
                    <div
                      v-if="getAddressContactOnly(club).type"
                      class="md:grid grid-cols-3 -md:border-b border-gray-500 py-4 md:mr-5"
                    >
                      <AtomText :content="getAddressContactOnly(club).col1" />
                      <div class="col-span-2 grid grid-cols-2 md:flex gap-4 -md:mt-2">
                        <AtomButton
                          v-if="getAddressContactOnly(club).link"
                          tag="a"
                          :href="getAddressContactOnly(club).link"
                          class="-md:self-end btn-outline btn-lg btn-icon btn-map"
                        >
                          <svg
                            width="19"
                            height="23"
                            viewBox="0 0 19 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.5 22.978L10.1577 22.4037C15.6887 17.5742 18.5 13.3572 18.5 9.65045C18.5 4.40112 14.4029 0.650452 9.5 0.650452C4.59705 0.650452 0.5 4.40112 0.5 9.65045C0.5 13.3572 3.31131 17.5742 8.84227 22.4037L9.5 22.978ZM9.5 20.3139C4.80661 16.084 2.5 12.4997 2.5 9.65045C2.5 5.54705 5.66411 2.65045 9.5 2.65045C13.3359 2.65045 16.5 5.54705 16.5 9.65045C16.5 12.4997 14.1934 16.084 9.5 20.3139ZM9.5 4.65045C12.2614 4.65045 14.5 6.88903 14.5 9.65045C14.5 12.4119 12.2614 14.6505 9.5 14.6505C6.73858 14.6505 4.5 12.4119 4.5 9.65045C4.5 6.88903 6.73858 4.65045 9.5 4.65045ZM6.5 9.65045C6.5 7.9936 7.84315 6.65045 9.5 6.65045C11.1569 6.65045 12.5 7.9936 12.5 9.65045C12.5 11.3073 11.1569 12.6505 9.5 12.6505C7.84315 12.6505 6.5 11.3073 6.5 9.65045Z"
                              fill="#282929"
                            />
                          </svg>
                        </AtomButton>
                        <div class="flex flex-col">
                          <AtomText :content="getAddressContactOnly(club).col2" />
                          <AtomText :content="getAddressContactOnly(club).col3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between pb-4 md:pb-6 pr-6 flex-wrap gap-2 mt-3 md:mt-4">
                    <div class="flex gap-2 flex-wrap">
                      <AtomButton
                        v-for="(control, i) in getControls(club)"
                        :key="i"
                        class="btn-sm"
                        :class="{ disabled: control.disabled }"
                        :to="control.to"
                        :tel="control.tel"
                        @click="control.action && control.action()"
                        >{{ control.title }}</AtomButton
                      >
                    </div>
                    <AtomButton class="btn-sm" :to="`/clubs/${club.slug}`">На страницу клуба</AtomButton>
                  </div>
                </div>
                <div class="border-t md:border-l border-gray-500 md:p-5 md:pr-0">
                  <AtomImageParallax
                    v-if="club.image"
                    :strapi-image-object="club.image"
                    img-classes="w-full"
                    class="h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </AccordionItem>
    </Accordion>
    <ModalVisitClub
      v-if="clubToVisit"
      :strapi-image-object="clubToVisit.headerImage || clubToVisit.image"
      @close="clubToVisit = null"
    />
  </div>
</template>

<script>
import Accordion from '../../atoms/accordion/AtomAccordion'
import AccordionItem from '../../atoms/accordion/AtomAccordionItem'
import AtomButton from '../../atoms/AtomButton'
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'
import ModalVisitClub from '../modals/VisitClub'

export default {
  name: 'ContactsClubs',
  builder: {
    component: 'contacts-clubs',
  },
  components: {
    AtomText,
    AtomImageParallax,
    AtomButton,
    AtomTitle,
    Accordion,
    AccordionItem,
    ModalVisitClub,
  },
  props: ['context'],
  data() {
    return {
      clubToVisit: null,
    }
  },
  computed: {
    clubs() {
      const clubs = this.$store.getters['modules/content/clubs']
      const cityId = this.context.cityId
      const hasAtLeastOne = Boolean(clubs.find(c => c.city.id == cityId))
      return clubs.filter(c => {
        return hasAtLeastOne ? c.city.id == cityId : true
      })
    },
  },
  methods: {
    getContactsWithoutAddress(club) {
      if (club.contacts && club.contacts.length) {
        return club.contacts.filter(({ type }) => type !== 'address')
      }
      return []
    },
    getAddressContactOnly(club) {
      if (club.contacts && club.contacts.length) {
        return club.contacts.find(({ type }) => type === 'address')
      }
      return {}
    },
    getControls(club) {
      return [
        club.open && {
          title: 'Расписание',
          to: `/timetable/${club.slug}/adult`,
        },
        club.spa && {
          title: 'SPA',
          to: `/spa/${club.slug}`,
        },
        club.open && {
          title: 'Посетить',
          action: () => {
            this.clubToVisit = club
          },
        },
        club.open &&
          club.phone && {
            title: '',
            tel: club.phone,
          },
        !club.open && {
          title: 'Скоро открытие',
          disabled: true,
        },
      ].filter(Boolean)
    },
  },
}
</script>
