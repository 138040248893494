<template>
  <div ref="list" class="list" data-scroll :data-scroll-id="scrollId">
    <div
      v-for="(it, i) in item"
      :key="it.title"
      :feature-index="i"
      class="last:border-b border-t border-gray-100 feature-element"
      :class="getOpacityClass(i)"
    >
      <AtomTitle class="py-3 md:pt-8 md:pb-10" tag="h2" :content="it.title" />
    </div>
  </div>
</template>

<script>
import AtomTitle from '../../atoms/AtomTitle'

let id = 0

export default {
  name: 'OrganismFeatures',
  components: { AtomTitle },
  props: ['item'],
  builder: {
    component: 'list-features',
  },
  data() {
    return {
      id: ++id,
      activeIndex: 0,
    }
  },
  computed: {
    scrollId() {
      return `feature-${this.id}`
    },
  },
  mounted() {
    this.$root.$loco.on('scroll', this.recalcOpacity)
  },
  beforeDestroy() {
    this.$root.$loco.off('scroll', this.recalcOpacity)
  },
  methods: {
    recalcOpacity(data) {
      const scrollData = data.currentElements[this.scrollId]
      if (scrollData) {
        let center = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2)
        while (center && center.hasAttribute && !center.hasAttribute('feature-index')) {
          center = center.parentNode
        }
        if (center && center.getAttribute) {
          this.activeIndex = Number(center.getAttribute('feature-index'))
        } else {
          this.activeIndex = scrollData.progress < 0.5 ? 0 : data.length - 1
        }
      }
    },
    getOpacityClass(i) {
      const classes = ['opacity-100', 'opacity-50', 'opacity-20', 'opacity-10']
      const index = Math.abs(this.activeIndex - i)
      return classes[Math.min(index, classes.length - 1)]
    },
  },
}
</script>

<style lang="postcss" scoped>
.feature-element {
  transition: opacity 0.5s var(--cubic-bezier);
}
</style>
