<template>
  <component :is="tag" class="relative">
    <span v-if="content" v-html="content" />
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AtomTitleMarquee',
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    content: {
      type: String,
      default: '',
    },
  },
}
</script>
