<template>
  <div
    class="item-training -md:last:pr-3 -md:min-w-[75%] -md:w-9/12 flex flex-col md:aspect-[427/453] justify-between overflow-hidden relative md:py-5 md:px-4 rounded-[10px] md:bg-gray-600"
  >
    <AtomTitle
      tag="h3"
      class="-md:hidden -md:px-2 -md:mt-2 -md:order-2 relative z-10"
      class-wrap="pb-[0.2em]"
      :content="card.title"
    />
    <div
      class="-md:relative -md:p-2 -md:pt-4 -md:order-1 aspect-[140/200] overflow-hidden flex flex-col md:justify-end md:aspect-auto h-full rounded-[10px] -md:bg-gray-600"
    >
      <AtomImageParallax
        class="img-hover absolute left-0 right-0 top-0 bottom-0"
        :class="{ ['-md:hidden']: card.imageMin }"
        :src="card.image"
      />
      <div class="img-mask absolute left-0 right-0 top-0 bottom-0">
        <AtomImage
          v-if="card.imageMin"
          class="img-hover md:hidden absolute left-0 right-0 top-0 bottom-0 w-full h-full object-cover"
          :src="card.imageMin"
        />
      </div>
      <div class="flex flex-row flex-wrap gap-3">
        <AtomTag
          v-for="i in card.tag"
          :key="i.name"
          tag="span"
          class="self-start !text-gray-100 border-gray-100!bg-gray-600"
          :content="i.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomImageParallax from '../../../atoms/AtomImageParallax'
import AtomImage from '../../../atoms/AtomImage'
import AtomTag from '../../../atoms/AtomTag'
import AtomTitle from '../../../atoms/AtomTitle'

export default {
  name: 'MoleculeCard',
  components: {
    AtomTitle,
    AtomTag,
    AtomImageParallax,
    AtomImage,
  },
  props: ['card'],
}
</script>

<style lang="postcss">
.item-training {
  .img-hover img {
    transition: transform 1s var(--ease);
  }
  &:hover {
    .img-hover img {
      transform: scale(1.2);
    }
    .tag {
      @apply md:!text-white;
      span {
        @apply md:text-white;
      }
      &:before {
        transform: translateZ(0);
      }
    }
  }
  .tag {
    @apply grid md:h-[32px] z-0 relative -md:border pointer-events-none;
    ::v-deep span {
      @apply md:truncate -md:text-left;
    }
    &:before {
      @apply absolute left-0 w-full z-[-1] bottom-[-0.1rem] md:content-[''] bg-gray-100;
      height: calc(100% + 0.2rem);
      transform: translate3d(0, 100%, 0);
      transition: transform 0.3s var(--ease-out);
    }
  }
}
</style>
