<template>
  <MarkdownLinkNavigation>
    <h2 class="text-image" v-html="description" />
  </MarkdownLinkNavigation>
</template>

<script>
import MarkdownLinkNavigation from '../../builder/core/MarkdownLinkNavigation'

export default {
  name: 'AtomTextImage',
  components: {
    MarkdownLinkNavigation,
  },
  builder: {
    component: 'text-image',
  },
  props: ['description'],
}
</script>

<style lang="postcss">
.text-image img {
  @apply inline-flex rounded-[70px] w-auto h-5 md:h-[4.1vw] object-cover align-bottom;
}
</style>
