<template>
  <div class="pl-5 border-l border-gray-100 text-base md:text-lg">
    <MarkdownLinkNavigation>
      <div v-if="description" v-html="description" />
    </MarkdownLinkNavigation>
  </div>
</template>

<script>
import MarkdownLinkNavigation from '../../builder/core/MarkdownLinkNavigation'

export default {
  name: 'AtomVerticalLineText',
  components: {
    MarkdownLinkNavigation,
  },
  builder: {
    component: 'vertilcal-line-text',
  },
  props: ['description'],
}
</script>
