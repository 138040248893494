<template>
  <div v-if="layout === 'gap-content'" class="grid grid-cols-12 md:gap-x-5 px-3 md:px-5">
    <div class="col-span-12 md:col-start-3 md:col-span-10">
      <slot />
    </div>
  </div>
  <div v-else-if="layout === 'gap-content-gap'" class="grid grid-cols-12 md:gap-x-5 px-3 md:px-5">
    <div class="col-span-12 md:col-start-3 md:col-end-10 md:pr-[13px]">
      <slot />
    </div>
  </div>
  <div v-else-if="layout === 'content-2gap'" class="grid grid-cols-12 md:gap-x-5 px-3 md:px-5">
    <div class="col-span-12 md:col-start-3 md:col-end-8">
      <slot />
    </div>
  </div>
  <div v-else-if="layout === 'gap-middle-gap'" class="grid grid-cols-12 md:gap-x-5 px-3 md:px-5">
    <div class="col-span-12 md:col-start-6 md:col-end-10">
      <slot />
    </div>
  </div>
  <div v-else-if="layout === 'content-gap'" class="grid grid-cols-12 md:gap-x-5 px-3 md:px-5">
    <div class="col-span-12 md:col-start-1 md:col-end-10">
      <slot />
    </div>
  </div>
  <div v-else-if="layout === 'gap-justify'" class="px-3 md:px-5">
    <slot />
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Layout',
  props: {
    layout: {
      type: String, // gap-content gap-content-gap
      required: false,
      default: null,
    },
  },
}
</script>
